import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import './CSS/MemberFormModal.css';

const MemberFormModal = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    mobileNumber: '',
    email: '',
    membershipFees: 1001 
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('http://localhost:5000/send-member-form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.text())
      .then((data) => {
        console.log('Success:', data);
        alert('Form submitted successfully!');
        handleClose();
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Error submitting form. Please try again.');
      });
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Member Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          {/* Form fields */}
          <div className="form-group">
            <label htmlFor="fullName" className="form-label">Name Of Member *</label>
            <input
              type="text"
              className="form-control"
              id="fullName"
              name="fullName"
              placeholder="Enter your full name"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="mobileNumber" className="form-label">Mobile Number *</label>
            <input
              type="tel"
              className="form-control"
              id="mobileNumber"
              name="mobileNumber"
              placeholder="Enter your phone number"
              value={formData.mobileNumber}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email" className="form-label">Email Id *</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="membershipFees" className="form-label">Membership Fees Rs</label>
            <input
              type="number"
              className="form-control"
              id="membershipFees"
              name="membershipFees"
              placeholder="Enter membership fees"
              value={formData.membershipFees}
              disabled
              required
            />
          </div>

          <p className="membership-para">
            Please deposit cash/ cheque / DD in favour with "Geetai Humankind
            Development Trust" payable at Pune, State bank of India A/C
            No:31167659452, Waraje Malwadi branch.IFSC: SBIN0011701. I wish to
            take a membership of Geetai's Magazines for years & I will try to
            give my best possible co-operation for various other activities of
            the trust.
          </p>
          <div className="text-center">
            <button type="submit" className="btn btn-primary">Submit</button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default MemberFormModal;

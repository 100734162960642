import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Modal, Form } from "react-bootstrap";
import "./CSS/ContactForms.css";

const ContactForms = () => {
  const [contactForms, setContactForms] = useState([]);
  const [filteredForms, setFilteredForms] = useState([]);
  const [editing, setEditing] = useState(null);
  const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [paymentModeFilter, setPaymentModeFilter] = useState("all");
  const [dateRange, setDateRange] = useState({ start: "", end: "" });

  useEffect(() => {
    // Fetch contact form data
    axios
      .get("http://localhost:5000/admin/contact-forms")
      .then((response) => {
        setContactForms(response.data);
        setFilteredForms(response.data);
      })
      .catch((error) => {
        console.error("Error fetching contact form data:", error);
      });
  }, []);

  useEffect(() => {
    setFilteredForms(
      contactForms.filter(
        (form) =>
          Object.values(form).some((value) =>
            String(value).toLowerCase().includes(searchQuery.toLowerCase())
          ) &&
          (dateRange.start === "" ||
            dateRange.end === "" ||
            (new Date(form.created_at) >= new Date(dateRange.start) &&
              new Date(form.created_at) <= new Date(dateRange.end)))
      )
    );
  }, [searchQuery, contactForms, paymentModeFilter, dateRange]);

  const handleDelete = (id) => {
    axios
      .delete(`http://localhost:5000/admin/contact-forms/${id}`)
      .then(() => {
        setContactForms(contactForms.filter((form) => form.id !== id));
      })
      .catch((error) => {
        console.error("Error deleting contact form data:", error);
      });
  };

  const handleEdit = (id) => {
    setEditing(id);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="admin-membership">
      <div className="header-section">
        <h2 className="admin-membership-heading">Manage Contact Forms</h2>
        <input
          type="text"
          placeholder="Search..."
          className="search-bar"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className="filter-section">
          <Form.Group controlId="dateRange">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              placeholder="Start Date"
              value={dateRange.start}
              onChange={(e) =>
                setDateRange({ ...dateRange, start: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group controlId="dateRange">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              placeholder="End Date"
              value={dateRange.end}
              onChange={(e) =>
                setDateRange({ ...dateRange, end: e.target.value })
              }
            />
          </Form.Group>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Subject</th>
            <th>Message</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredForms.map((form) => (
            <tr key={form.id}>
              <td>{form.id}</td>
              <td>{form.name}</td>
              <td>{form.email}</td>
              <td>{form.contact}</td>
              <td>{form.subject}</td>
              <td>{form.message}</td>
              <td>{new Date(form.created_at).toLocaleString()}</td>
              <td>{new Date(form.updatedAt).toLocaleString()}</td>
              <td className="button-td">
                <Button
                  className="edit-btn"
                  variant="primary"
                  onClick={() => handleEdit(form.id)}
                >
                  Edit
                </Button>
                <Button
                  className="delete-btn"
                  variant="danger"
                  onClick={() => handleDelete(form.id)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {editing && (
        <EditContactFormModal
          id={editing}
          show={show}
          onClose={handleClose}
          onUpdate={() => {
            handleClose();
          }}
        />
      )}
    </div>
  );
};

const EditContactFormModal = ({ id, show, onClose, onUpdate }) => {
  const [form, setForm] = useState(null);

  useEffect(() => {
    axios
      .get(`http://localhost:5000/admin/contact-forms/${id}`)
      .then((response) => setForm(response.data))
      .catch((error) =>
        console.error("Error fetching contact form data:", error)
      );
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .put(`http://localhost:5000/admin/contact-forms/${id}`, form)
      .then(() => {
        onUpdate();
        onClose();
      })
      .catch((error) =>
        console.error("Error updating contact form data:", error)
      );
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Contact Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form ? (
          <Form onSubmit={handleSubmit} className="edit-form">
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={form.name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={form.email}
                onChange={(e) => setForm({ ...form, email: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formContact">
              <Form.Label>Contact</Form.Label>
              <Form.Control
                type="text"
                value={form.contact}
                onChange={(e) => setForm({ ...form, contact: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formSubject">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                value={form.subject}
                onChange={(e) => setForm({ ...form, subject: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control
                type="text"
                value={form.message}
                onChange={(e) => setForm({ ...form, message: e.target.value })}
              />
            </Form.Group>
            <div className="saveButton">
              <Button variant="primary" type="submit">
                Save
              </Button>
              <Button variant="secondary" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </Form>
        ) : (
          <p>Loading...</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ContactForms;

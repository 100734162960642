 // src/admin/AdminPanel.js
import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faHandsHelping, faCalendarAlt, faDonate, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './CSS/AdminPanel.css';
import AdminHeader from './AdminHeader'; 


const AdminPanel = () => (
    <div>
        <AdminHeader />
        <div className="admin-main">
            <aside className= "admin-sidebar">
                <ul>
                    <li>
                        <Link to="/admin/membership">
                            <FontAwesomeIcon icon={faUsers} /> Manage Memberships
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/sponsorship">
                            <FontAwesomeIcon icon={faHandsHelping} /> Manage Sponsorships
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/event-registration">
                            <FontAwesomeIcon icon={faCalendarAlt} /> Manage Event Registrations
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/donation">
                            <FontAwesomeIcon icon={faDonate} /> Manage Donations
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/contact">
                            <FontAwesomeIcon icon={faEnvelope} /> Manage Contact Forms
                        </Link>
                    </li>
                </ul>
            </aside>
            <main className="admin-content">
                <Outlet />
            </main>
        </div>
    </div>
);

export default AdminPanel;

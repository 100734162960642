import React, { useState } from "react";
import "./CSS/Janadhar.css";
import j1 from "../assets/j1.png";
import j2 from "../assets/j2.png";
import j3 from "../assets/j3.png";
import j4 from "../assets/j4.png";
import j5 from "../assets/j5.png";
import j6 from "../assets/j6.jpg";
import j7 from "../assets/j7.jpg";
import humanity from "../assets/humanity.jpg";
import MemberFormModal from "../components/MemberFormModal";


const Janadhar = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    console.log("Show Modal Clicked");
    setShowModal(true);
  };
  const handleCloseModal = () => {
    console.log("Close Modal Clicked");
    setShowModal(false);
  };
  return (
    <div className="janadhar-container">
      <span className="janadhar-span">
        What We Are Boing - JANADHAR And SELF SUFFCIANT VILLAGE
      </span>
      <h3 className="janadhar-heading">SELF SUFFICIANT VILLAGE</h3>
      <span className="janadhar-span">
        " Anything Is Possible When You Have The Right People There To Support
        You. "
      </span>{" "}
      <hr />

      
      <div className="janadhar-box">
        <h1 className="janadhar-heading">अन्न स्वावलंबन</h1>
        <p className="janadhar-text">
          गोबर गॅस मधुन निघालेली शेण स्लरी, विविध पिकांना खाद्य सेंद्रिय खत
          म्हणुन वापरण्यात येते. कुठल्याही प्रकारे रासायनिक खत बाहेरून आणण्याची
          गरज नाही . ह्या स्लरी युक्त सेंद्रिय खतावर अर्धा एकर भाजीपाला , ३
          एकरमध्ये लिंबूची ३00 झाडे, १0 एकरमध्ये आवळ्याची ९८0 झाडे, १५ एकरमध्ये
          सागवानाची ६५00 झाडे, नर्सरी, हळद पिक, धान्य पिक, चारा पिक अशी विविध
          पिके सेंद्रिय पद्धतीने घेण्यात येतात , स्वावलंबन तत्वाणुसार संपुर्ण
          स्वनिर्मित पद्धतीने शेती व्यवसाय करण्यात येतो, ह्याचे जिवंत उदाहरण
          म्हणजे 3५ एकराचे जीवीत प्रतिरूप, प्रतिदर्शक, प्रक्षेत्र तयार झाले आहे.
          काडी कचरा व शेण ह्यांच्या माध्यमातुन गांडुळ खत करण्यात येते आणि
          त्याच्या उपयोग नर्सरीमध्ये, फुलझाडांना अणि भाजीपाला पिकाकरीता करण्यात
          येतो. स्वतःच्या शेतामध्ये सेंद्रिय पद्धतीने तयार झालेल्या फळापासून
          स्वतःच्या फळ प्रक्रिया केंद्रामध्ये आवळ्यापासून आवळा लोणचे , आवळा
          मुरंबा ,आवळा कॅंडी, आवळा सुपारी , आवळा कंटी ,आवळा जामुन, आवळा सुपारी ,
          आवळा चूर्ण , आवळा पावडर इत्यादी तसेच लिंबु पासून लिंबु लोणचे , लिंबु
          मिरची लोणचे , लिंबु उपवास लोणचे , लेमन क्रश आणि आंब्यापासून आंबा लोणचे
          ,आंबा चॉकलेट आणि सोयाबीन पासून सोया कॉफी इत्यादी उत्पादने तयार केली
          जातात
        </p>
      </div>
      <div>
        <img src={j1} alt="Image" className="three-images"></img>
      </div>


      <hr />
      {/* Alternating Image and Content Sections */}
      <div className="image-content-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-md-1">
              <img src={j2} alt="Left Image" className="about-image-5" />
            </div>
            <div className="col-md-6 order-md-2">
              <div className="content">
                <h2 className="content-heading">वस्त्र स्वावलंबन</h2>
                <p className="content-para">
                  महात्मा गांधीजींचे वस्त्र स्वावलंबन जगप्रसिद्ध आहे. त्याचाच
                  वसा घेऊन वस्त्र स्वावलंबनेच्या दृष्टीने काम करण्याची प्रेरणा
                  घेतली. ह्या माध्यमातून कापूस ते कापड प्रक्रिया, स्वनिर्मित
                  असावी ह्या दृष्टीने प्रथम कापदापासून सूत निर्मिती करण्याची
                  प्रक्रिया सुरू केली. प्राथमिक अवस्थेत स्वयंमचलित चरख्याद्वारे
                  सूत निर्मिती करणे सुरू केले. पुढील प्रक्रिया टप्याटप्याने
                  कार्यरत होत आहे. स्वप्न साकार करण्याच्या दृष्टीने आम्ही
                  प्रयत्नशील आहोच आणि उर्वरीत काम प्रगती पथावर आहे. ह्या
                  कार्यामध्ये महिलांचा सहभाग वाढविणे आणि त्यांच्या
                  आत्मनिर्भरतेसाठी विशेष प्रयत्न करण्यात आले आहेत. गावातील युवक
                  आणि युवकांना प्रशिक्षित करून रोजगाराची संधी उपलब्ध करून दिली
                  जात आहे. स्वदेशी आणि स्वावलंबनाच्या तत्त्वावर आधारित ह्या
                  उपक्रमामुळे स्थानिक अर्थव्यवस्थेला मोठा आधार मिळत आहे. या
                  माध्यमातून आपली संस्कृती आणि परंपरा जपण्याचाही प्रयत्न करण्यात
                  येत आहे.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 order-md-2">
              <img src={j3} alt="Right Image" className="about-image-5" />
            </div>
            <div className="col-md-6 order-md-1">
              <div className="content">
                <h2 className="content-heading">शिक्षण स्वावलंबन</h2>
                <p className="content-para">
                  लहान मुलांवर चांगले संस्कार होण्यासाठी बालसंस्कार शिवीर घेणे
                  १0 वर्षांपासुन सुरु आहे. ह्यामध्ये योगापासुन संगणकापर्यंत
                  उपक्रम शिबीरातील विद्यार्थ्यांकडून केल्या जातात.याच
                  उपक्रपामधून पुढील शिक्षणाची गरज लक्षात घेऊन प्रि- प्रायमरी य
                  प्रायमरी वेदांत पब्लीक स्कुल ह्या नावाने चालू करून तेथे लोकल व
                  ग्लोवल अश्या प्रकारचे उपक्रम राबविले जातात. शेती हा देशाचा कणा
                  आहे त्यामुळे कृषी विद्यापीठ अंतर्गत कृषी शिक्षण हा अत्यंत
                  महत्वाचा भाग आहे. कृषी महाविद्यालय ३ वर्षापासून सुरू करून
                  ५00पेक्षा जास्त विद्यार्थी कृषी शिक्षण घेत आहेत. आमचे येथे
                  विद्यार्थी स्वत:च्या पायावर उभा राहण्याच्या दृष्टीने स्वतंत्र
                  अभ्यासक्रम सर्वांगीण विकास ह्या नावाने तयार केला असून
                  त्यामध्ये त्यांना नैपुण्य वर्धक शिक्षण दिले जाते. शेती
                  उद्योगाला आधुनिकतेची सुद्धा साथ असणे गरजेचे आहे. कृषी
                  शास्त्रानुसार सखोल ज्ञानाची जोड हवीच राष्ट्रीय आंतरराष्ट्रीय
                  शेती पद्धतीने विषयी माहिती असणे गरजेचे आहे. कृषीशास्त्र अफाट
                  अर्माद आहे परंतु आधुनिकता स्वावलंबनामध्ये परावर्तीत करून
                  सेंद्रिय शेती पद्धतीची कास धरून तरूण पिढीला कृतीयुक्त
                  मार्गदर्शन व्हावे ह्या दृष्टीने शिक्षण स्वावलंबनाचा मार्ग
                  स्विकारण्यात आला आहे.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 order-md-1">
              <img src={j4} alt="Left Image" className="about-image-5" />
            </div>
            <div className="col-md-6 order-md-2">
              <div className="content">
                <h2 className="content-heading">स्वास्थ्य स्वावलंबन</h2>
                <p className="content-para">
                  प्राणायम, योगासन यांचे नियमित वर्ग चालविले जातात. त्याचा
                  प्रचार प्रसार संपुर्ण जिल्हात केला जातो. निसर्गोपचार केंद्र
                  आमखेडा येथे चालू करण्यात आले आहे. नैसर्गिक उपचाराने व खानपान
                  आणि राहणीमानामध्ये बदल केल्यास स्वास्थ्य कसे चांगले राहू शकते
                  त्या बद्दल सामुपदेशन करण्यात येते. व्यसनमुक्ती हा खूप महत्वाचा
                  विषय आहे. प्रत्येक घरामध्ये वेगवेगळी व्यसने जडलेली आहेत
                  त्याबाबत जनजागृती करून त्यासाठी उपाय योजना केली जाते. या
                  केंद्रात विविध नैसर्गिक उपचार पद्धती, जसे की हर्बल थेरेपी,
                  हायड्रोथेरेपी, आणि मड थेरेपी यांचा वापर करून रोगांचे निदान आणि
                  उपचार केले जातात. आमच्या तज्ञांनी तयार केलेले आहार योजना आणि
                  व्यायाम कार्यक्रम यामुळे आरोग्यवर्धनाची दिशा दिली जाते.
                  व्यसनमुक्तीसाठी विशेष शिबिरे आणि कार्यशाळा आयोजित केल्या
                  जातात. यात सहभागी झालेल्या लोकांना मानसिक, शारीरिक, आणि भावनिक
                  सहाय्य दिले जाते. समाजात स्वास्थ आणि समृद्धी वाढविण्याच्या
                  उद्देशाने आम्ही विविध आरोग्य जागरूकता कार्यक्रम राबवतो. आमच्या
                  प्रयत्नांमुळे अनेक लोकांच्या जीवनात सकारात्मक बदल घडविण्यात यश
                  आले आहे.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> <hr/>
      <div className="image-5">
        <img src={j5} alt="Image"></img>
      </div> <hr/>
      <h3 className="janadhar-heading">JANADHAR</h3>
      <div className="image-5">
        <img src={j6} alt="image"></img>
        <img src={j7} alt="image"></img>
      </div> <hr/>
      <div
        className="humanity-section"
        style={{ backgroundImage: `url(${humanity})` }}
      >
        <div className="humanity-content">
          <h2 className="humanity-heading">
            Let's Change The World <br /> With Humanity
          </h2>
          <button className="btn btn-primary" onClick={handleShowModal}>
            Become a Member
          </button>
        </div>
      </div>
      <MemberFormModal show={showModal} handleClose={handleCloseModal} />
    </div>
  );
};

export default Janadhar;

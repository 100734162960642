import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CSS/AdminDonation.css";

const AdminDonation = () => {
  const [donations, setDonations] = useState([]);
  const [editing, setEditing] = useState(null);
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [paymentModeFilter, setPaymentModeFilter] = useState("all");
  const [dateRange, setDateRange] = useState({ start: "", end: "" });

  useEffect(() => {
    axios
      .get("http://localhost:5000/admin/donations")
      .then((response) => setDonations(response.data))
      .catch((error) => console.error("Error fetching donation data:", error));
  }, []);

  const handleDelete = (id) => {
    axios
      .delete(`http://localhost:5000/admin/donations/${id}`)
      .then(() =>
        setDonations(donations.filter((donation) => donation.id !== id))
      )
      .catch((error) => console.error("Error deleting donation:", error));
  };

  const handleEdit = (id) => {
    setEditing(id);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const filteredDonations = donations.filter(
    (donation) =>
      (searchTerm === "" ||
        donation.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        donation.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        donation.mobileNumber
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) &&
      (paymentModeFilter === "all" ||
        donation.paymentMode === paymentModeFilter) &&
      (dateRange.start === "" ||
        dateRange.end === "" ||
        (new Date(donation.createdAt) >= new Date(dateRange.start) &&
          new Date(donation.createdAt) <= new Date(dateRange.end)))
  );

  const handleUpdate = (updatedDonation) => {
    setDonations(
      donations.map((donation) =>
        donation.id === updatedDonation.id ? updatedDonation : donation
      )
    );
    handleClose();
  };

  return (
    <div className="admin-donation">
      <div className="header-section">
        <h2 className="admin-membership-heading">Donations</h2>
        <input
          type="text"
          placeholder="Search..."
          className="search-bar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="filter-section">
          <Form.Group controlId="paymentModeFilter">
            <Form.Label>Payment Mode</Form.Label>
            <Form.Control
              as="select"
              value={paymentModeFilter}
              onChange={(e) => setPaymentModeFilter(e.target.value)}
            >
              <option value="all">All</option>
              <option value="online">Online</option>
              <option value="cash">Cash</option>
              <option value="cheque">Cheque</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="dateRange">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              placeholder="Start Date"
              value={dateRange.start}
              onChange={(e) =>
                setDateRange({ ...dateRange, start: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group controlId="dateRange">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              placeholder="End Date"
              value={dateRange.end}
              onChange={(e) =>
                setDateRange({ ...dateRange, end: e.target.value })
              }
            />
          </Form.Group>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Remark</th>
            <th>Payment Mode</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredDonations.map((donation) => (
            <tr key={donation.id}>
              <td>{donation.id}</td>
              <td>{donation.name}</td>
              <td>{donation.email}</td>
              <td>{donation.phone}</td>
              <td>{donation.amount}</td>
              <td>{donation.status}</td>
              <td>{donation.remark}</td>
              <td>{donation.paymentMode}</td>
              <td>{new Date(donation.createdAt).toLocaleString()}</td>
              <td>{new Date(donation.updatedAt).toLocaleString()}</td>
              <td className="button-td">
                <Button
                  onClick={() => handleEdit(donation.id)}
                  className="edit-btn"
                >
                  Edit
                </Button>
                <Button
                  onClick={() => handleDelete(donation.id)}
                  className="delete-btn"
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Edit Donation Modal */}
      {editing && (
        <EditDonationModal
          id={editing}
          show={show}
          onClose={handleClose}
          onUpdate={handleUpdate}
        />
      )}
    </div>
  );
};

const EditDonationModal = ({ id, show, onClose, onUpdate }) => {
  const [donation, setDonation] = useState(null);

  useEffect(() => {
    axios
      .get(`http://localhost:5000/admin/donations/${id}`)
      .then((response) => setDonation(response.data))
      .catch((error) => console.error("Error fetching donation data:", error));
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .put(`http://localhost:5000/admin/donations/${id}`, donation)
      .then(() => {
        onUpdate(donation);
      })
      .catch((error) => console.error("Error updating donation:", error));
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Donation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {donation ? (
          <Form onSubmit={handleSubmit} className="edit-form">
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={donation.name}
                onChange={(e) =>
                  setDonation({ ...donation, name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={donation.email}
                onChange={(e) =>
                  setDonation({ ...donation, email: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                value={donation.phone}
                onChange={(e) =>
                  setDonation({ ...donation, phone: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formAmount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                value={donation.amount}
                onChange={(e) =>
                  setDonation({ ...donation, amount: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={donation.status}
                onChange={(e) =>
                  setDonation({ ...donation, status: e.target.value })
                }
                className="dropdown-option"
              >
                <option value="success">Success</option>
                <option value="pending">Pending</option>
                <option value="rejected">Rejected</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formRemark">
              <Form.Label>Remark</Form.Label>
              <Form.Control
                as="textarea"
                value={donation.remark}
                onChange={(e) =>
                  setDonation({ ...donation, remark: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formPaymentMode">
              <Form.Label>Payment Mode</Form.Label>
              <Form.Control
                as="select"
                value={donation.paymentMode}
                onChange={(e) =>
                  setDonation({ ...donation, paymentMode: e.target.value })
                }
              >
                <option value="online">Online</option>
                <option value="cash">Cash</option>
                <option value="cheque">Cheque</option>
              </Form.Control>
            </Form.Group>
            <div className="saveButton">
              <Button variant="primary" type="submit">
                Save
              </Button>
              <Button variant="secondary" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </Form>
        ) : (
          <p>Loading...</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AdminDonation;

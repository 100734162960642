import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import './CSS/AdminMembership.css';

const AdminMembership = () => {
    const [members, setMembers] = useState([]);
    const [editing, setEditing] = useState(null);
    const [show, setShow] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [paymentModeFilter, setPaymentModeFilter] = useState('all');
    const [dateRange, setDateRange] = useState({ start: '', end: '' });

    useEffect(() => {
        axios.get('http://localhost:5000/admin/membership')
            .then(response => setMembers(response.data))
            .catch(error => console.error('Error fetching membership data:', error));
    }, []);

    const handleDelete = (id) => {
        axios.delete(`http://localhost:5000/admin/membership/${id}`)
            .then(() => setMembers(members.filter(member => member.id !== id)))
            .catch(error => console.error('Error deleting member:', error));
    };

    const handleEdit = (id) => {
        setEditing(id);
        setShow(true);
    };

    const handleClose = () => setShow(false);

    // Filter members based on search term, payment mode, and date range
    const filteredMembers = members
        .filter(member => 
            (searchTerm === '' || 
             member.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
             member.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
             member.mobileNumber.toLowerCase().includes(searchTerm.toLowerCase()))
            &&
            (paymentModeFilter === 'all' || member.paymentMode === paymentModeFilter)
            &&
            (dateRange.start === '' || dateRange.end === '' || 
            (new Date(member.createdAt) >= new Date(dateRange.start) && new Date(member.createdAt) <= new Date(dateRange.end)))
        );

    return (
        <div>
            <div className="admin-membership">
                <div className="header-section">
                    <h2 className="admin-membership-heading">Memberships</h2>
                    <input
                        type="text"
                        placeholder="Search..."
                        className="search-bar"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="filter-section">
                        <Form.Group controlId="paymentModeFilter">
                            <Form.Label>Payment Mode</Form.Label>
                            <Form.Control 
                                as="select" 
                                value={paymentModeFilter} 
                                onChange={(e) => setPaymentModeFilter(e.target.value)}
                            >
                                <option value="all">All</option>
                                <option value="online">Online</option>
                                <option value="cash">Cash</option>
                                <option value="cheque">Cheque</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="dateRange">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control 
                            
                                type="date" 
                                placeholder="Start Date"
                                value={dateRange.start}
                                onChange={(e) => setDateRange({ ...dateRange, start: e.target.value })}
                            />
                          </Form.Group>
                          <Form.Group controlId="dateRange">
                             <Form.Label>End Date</Form.Label>
                            <Form.Control 
                                type="date" 
                                placeholder="End Date"
                                value={dateRange.end}
                                onChange={(e) => setDateRange({ ...dateRange, end: e.target.value })}
                            />
                        </Form.Group>
              
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Fees</th>
                            <th>Status</th>
                            <th>Remark</th>
                            <th>Payment Mode</th>
                            <th>Created At</th>
                            <th>Updated At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredMembers.map(member => (
                            <tr key={member.id}>
                                <td>{member.id}</td>
                                <td>{member.fullName}</td>
                                <td>{member.email}</td>
                                <td>{member.mobileNumber}</td>
                                <td>{member.membershipFees}</td>
                                <td>{member.status}</td>
                                <td>{member.remark}</td>
                                <td>{member.paymentMode}</td>
                                <td>{new Date(member.createdAt).toLocaleString()}</td>
                                <td>{new Date(member.updatedAt).toLocaleString()}</td>
                                <td className="button-td">
                                    <Button className="edit-btn" onClick={() => handleEdit(member.id)}>Edit</Button>
                                    <Button className="delete-btn" onClick={() => handleDelete(member.id)}>Delete</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Edit Membership Modal */}
            {editing && (
                <EditMembershipModal 
                    id={editing}
                    show={show}
                    onClose={handleClose}
                    onUpdate={() => {
                        handleClose();
                        // Optionally refresh the data here
                    }}
                />
            )}
        </div>
    );
};

const EditMembershipModal = ({ id, show, onClose, onUpdate }) => {
    const [member, setMember] = useState(null);

    useEffect(() => {
        axios.get(`http://localhost:5000/admin/membership/${id}`)
            .then(response => setMember(response.data))
            .catch(error => console.error('Error fetching member data:', error));
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.put(`http://localhost:5000/admin/membership/${id}`, member)
            .then(() => {
                onUpdate();
                onClose();
            })
            .catch(error => console.error('Error updating member data:', error));
    };

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Member</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {member ? (
                    <Form onSubmit={handleSubmit} className="edit-form">
                        <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={member.fullName} 
                                onChange={(e) => setMember({ ...member, fullName: e.target.value })} 
                            />
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control 
                                type="email" 
                                value={member.email} 
                                onChange={(e) => setMember({ ...member, email: e.target.value })} 
                            />
                        </Form.Group>
                        <Form.Group controlId="formMobile">
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={member.mobileNumber} 
                                onChange={(e) => setMember({ ...member, mobileNumber: e.target.value })} 
                            />
                        </Form.Group>
                        <Form.Group controlId="formFees">
                            <Form.Label>Fees</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={member.membershipFees} 
                                onChange={(e) => setMember({ ...member, membershipFees: e.target.value })} 
                            />
                        </Form.Group>
                        <Form.Group controlId="formStatus">
                            <Form.Label>Status</Form.Label>
                            <Form.Control 
                                as="select" 
                                value={member.status} 
                                onChange={(e) => setMember({ ...member, status: e.target.value })}
                                className="dropdown-option"
                            >
                                <option value="success">Success</option>
                                <option value="pending">Pending</option>
                                <option value="rejected">Rejected</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formRemark">
                            <Form.Label>Remark</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                value={member.remark} 
                                onChange={(e) => setMember({ ...member, remark: e.target.value })} 
                            />
                        </Form.Group>
                        <Form.Group controlId="formPaymentMode">
                            <Form.Label>Payment Mode</Form.Label>
                            <Form.Control 
                                as="select" 
                                value={member.paymentMode} 
                                onChange={(e) => setMember({ ...member, paymentMode: e.target.value })}
                            >
                                <option value="online">Online</option>
                                <option value="cash">Cash</option>
                                <option value="cheque">Cheque</option>
                            </Form.Control>
                        </Form.Group>
                        <div className="saveButton">
                            <Button variant="primary" type="submit">Save</Button>
                            <Button variant="secondary" onClick={onClose}>Cancel</Button>
                        </div>
                    </Form>
                ) : <p>Loading...</p>}
            </Modal.Body>
        </Modal>
    );
};

export default AdminMembership;

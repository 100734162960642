// Donation.js
import React, { useState } from "react";
import "./CSS/Donation.css";
import GalleryModal from "../components/GalleryModal";
import DonationPaymentModal from "../components/DonationPaymentModal";
import photo1 from "../assets/const.jpg";
import photo2 from "../assets/COA51.jpeg";
import photo3 from "../assets/pool.jpg";
import photo4 from "../assets/v2.jpg";
import photo5 from "../assets/v6.jpg";
import photo6 from "../assets/v8.jpeg";
import education from "../assets/education.jpg";
import education2 from "../assets/donation.jpg";

const Donation = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const images = [photo1, photo2, photo3, photo4, photo5, photo6];

  return (
    <div className="donation-container">
      <div className="row row-1">
        <div className="col-md-6 order-md-1">
          <img src={education} alt="Left Image" className="donation-image" />
        </div>
        <div className="col-md-6 order-md-2">
          <div className="content">
            <h2 className="donation-heading">Contribute and Support</h2>
            <p className="donation-text">
              Through our programmes at GEETAI Humankind Development Trust
              (GHKDT), we have initiated a journey to empower people and
              transform their lives. With 21 years of dedication to rural
              development, focusing on Education, Health, Social, Agriculture,
              and Spiritual fields (E.H.S.A.S.), there are yet miles to go, and
              the task is to meet the emerging needs of the People and the
              Planet. This cannot be a single-handed effort.
            </p>
            <p className="donation-text">
              We invite you all – the discerning, the sensitive, and the
              envisioning citizens of this planet – to participate in this
              effort. This is an invitation to Kinship, one which can take many
              different forms:
            </p>
          </div>
        </div>
      </div>

      <div className="row row-1">
        <div className="col-md-6 order-md-2">
          <img src={education2} alt="Right Image" className="donation-image" />
        </div>
        <div className="col-md-6 order-md-1">
          <div className="content">
            <h2 className="donation-heading-2">Donate for a Cause</h2>
            <p className="donation-text">
              Your financial contributions to specific programme initiatives at
              GEETAI Humankind Development Trust (GHKDT) can make a significant
              difference: making drinking water available to parched
              communities; helping a girl child to have a solid foothold in life
              through education and support; enabling women’s groups or farmer
              organisations to manage their journey on the road of development;
              and creating sustainable livelihoods for a group of families. With
              our focus on Education, Health, Social, Agriculture, and Spiritual
              fields (E.H.S.A.S.), your support can help us continue to
              transform lives and empower communities.
            </p>
          </div>
        </div>
      </div>
      <hr />

      <GalleryModal images={images} />

      <hr />

      {/* Table Section */}
      <div className="table-container">
        <table className="donation-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Institute</th>
              <th>Section</th>
              <th>Reason</th>
              <th>Remark</th>
              <th>Donation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Abhish Singh</td>
              <td>COE</td>
              <td>2nd year</td>
              <td>Fee payable to school</td>
              <td>Nice student</td>
              <td>
                <button className="donate-button" onClick={handleShow}>
                  Donate
                </button>
              </td>
            </tr>
            <tr>
              <td>Test</td>
              <td>Poha</td>
              <td>1 A</td>
              <td>Test</td>
              <td>Test</td>
              <td>
                <button className="donate-button" onClick={handleShow}>
                  Donate
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <DonationPaymentModal show={showModal} handleClose={handleClose} />
    </div>
  );
};

export default Donation;

import React, { useState } from "react";
import "./CSS/Contacts.css";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import humanity from "../assets/humanity.jpg";
import MemberFormModal from "../components/MemberFormModal";

const Contacts = () => {
  const [showModal, setShowModal] = useState(false);
  const [contactFormData, setContactFormData] = useState({
    name: "",
    email: "",
    contact: "",
    subject: "",
    message: "",
  });

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleContactFormChange = (e) => {
    const { id, value } = e.target;
    setContactFormData({
      ...contactFormData,
      [id]: value,
    });
  };

  const handleContactFormSubmit = async (e) => {
    e.preventDefault();

    fetch("http://localhost:5000/send-contact-form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contactFormData),
    })
      .then((response) => response.text())
      .then((data) => {
        console.log("Success:", data);
        alert("Your message has been sent successfully!");
        setContactFormData({
          name: "",
          email: "",
          contact: "",
          subject: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Error sending message. Please try again.");
      });
  };
  return (
    <div className="contact-container">
      <h3 className="location-heading">Our Locations</h3>
      <hr />
      <div className="maps-wrapper">
        <div className="map-item">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7568.634876364586!2d73.79058500000001!3d18.469275000000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2be3b1a3cd9d5%3A0x413a1562818e8aff!2sRamelex%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1720677303545!5m2!1sen!2sin"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          <div className="address">
            <FaMapMarkerAlt className="address-icon" />
            <p>
              <span className="contact-span">Geetai Trust:</span> <br />
              Dangat Industrial Estate, S No. 81/2, NDA Road, <br /> Shivane
              Pune, Maharashtra 411023
            </p>
          </div>
        </div>
        <div className="map-item">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d29946.979698128067!2d77.091699!3d20.243382!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd0ddfaad580ec7%3A0xf3a878c41f40ef97!2sSaraswati%20Agrotech%20Farm%2C%20Ahinsatirth%20Aamkheda!5e0!3m2!1sen!2sin!4v1720677332152!5m2!1sen!2sin"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          <div className="address">
            <FaMapMarkerAlt className="address-icon" />
            <p>
              <span className="contact-span">Farm:</span> <br />
              Sarswati Agrotech Farm(Ahinsatirth) Amkheda, <br />
              Tal.Malegaon Dist.washim,Maharashtra 444503
            </p>
          </div>
        </div>
      </div>
      <hr />

      <div className="contact-info-container">
        <div className="contact-form-container">
          <h3 className="form-heading-2">Get in Touch</h3>
          <hr />
          <form className="contact-form" onSubmit={handleContactFormSubmit}>
            <input
              type="text"
              id="name"
              placeholder="Name"
              value={contactFormData.name}
              onChange={handleContactFormChange}
              required
            />
            <input
              type="email"
              id="email"
              placeholder="Email"
              value={contactFormData.email}
              onChange={handleContactFormChange}
              required
            />
            <input
              type="tel"
              id="contact"
              placeholder="Contact"
              value={contactFormData.contact}
              onChange={handleContactFormChange}
              required
            />
            <input
              type="text"
              id="subject"
              placeholder="Subject"
              value={contactFormData.subject}
              onChange={handleContactFormChange}
              required
            />
            <textarea
              id="message"
              placeholder="Message"
              value={contactFormData.message}
              onChange={handleContactFormChange}
              required
            ></textarea>
            <button type="submit">Submit</button>
          </form>
        </div>
        <div className="contact-info-box">
          <div className="contact-info">
            <h3 className="location-heading">Contact Information</h3>
            <hr />
            <p>
              <FaPhoneAlt className="icon" />
            </p>
            <p className="tele">+91-9822872010 / +91-8380073023</p>
            <p className="twenty-four">24 * 7</p>
            <br />
            <p>
              <FaEnvelope className="icon" />
            </p>
            <p className="email">info@geetai.org</p>
            <p className="email-desc">Send us your query anytime!</p>
          </div>
        </div>
      </div>
      <hr />
      <div
        className="humanity-section"
        style={{ backgroundImage: `url(${humanity})` }}
      >
        <div className="humanity-content">
          <h2 className="humanity-heading">
            Let's Change The World <br /> With Humanity
          </h2>
          <button className="btn btn-primary" onClick={handleShowModal}>
            Become a Member
          </button>
        </div>
      </div>
      <MemberFormModal show={showModal} handleClose={handleCloseModal} />
    </div>
  );
};

export default Contacts;

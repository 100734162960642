import React from "react";
import "./CSS/Pillers.css";
import dept1 from "../assets/himmat_jogdand.png";
import dept2 from "../assets/rajesh_ghuge.png";
import dept3 from "../assets/ashish_dahatonde.png";

const Pillers = () => {
  return (
    <div className="pillers">
      <div>
        <h1 className="pillers-heading">Our Vice Presidents</h1>
        <hr />
        <div className="geetai-pillers">
          <div className="container">
            <div className="row">
              {/* Department Card 1 */}
              <div className="col-md-4">
                <div className="pillers-card">
                  <img
                    src={dept1}
                    className="card-img-top-pillers"
                    alt="Himmat M. Jogdand"
                  />
                  <div className="pillers-card-body">
                    <p className="pillers-card-text">
                      Mr. Himmat M. Jogdand
                      <br />
                      Vice President, Dept Of Agriculture
                      <br />
                      Phone: +91-9822872039
                      <br />
                      Email: hmj@ramelex.com
                    </p>
                  </div>
                </div>
              </div>

              {/* Department Card 2 */}
              <div className="col-md-4">
                <div className="pillers-card">
                  <img
                    src={dept2}
                    className="card-img-top-pillers"
                    alt="Rajesh R. Ghuge"
                  />
                  <div className="pillers-card-body">
                    <p className="pillers-card-text">
                      Mr. Rajesh R. Ghuge
                      <br />
                      Vice President, Dept Of Social Affairs
                      <br />
                      Phone: +91-9011034355 / 9921683676
                      <br />
                      Email: rajeshghuge72@gmail.com
                    </p>
                  </div>
                </div>
              </div>

              {/* Department Card 3 */}
              <div className="col-md-4">
                <div className="pillers-card">
                  <img
                    src={dept3}
                    className="card-img-top-pillers"
                    alt="Ashish S. Dahatonde"
                  />
                  <div className="pillers-card-body">
                    <p className="pillers-card-text">
                      Mr. Ashish S. Dahatonde
                      <br />
                      Vice President, Dept Of Education
                      <br />
                      Phone: +91-9130095161
                      <br />
                      Email: vpedu@geetai.org / ashish@geetai.org
                    </p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pillers;

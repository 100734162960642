import React from "react";
import "./CSS/Pillers.css";
import commitee from "../assets/Commitee_names.jpeg";

const Commitee = () => {
  return (
    <div className="pillers">
      <div>
        <h1 className="pillers-heading">Our Advisory Committee</h1>
        <hr />
        <img
              className="trusty"
              src={commitee}
              alt="Advisory Committee Image"
            />
      </div>
    </div>
  );
};

export default Commitee;

import React from "react";
import "./CSS/Vision.css";
import AboutUs1 from "../assets/vision.png";
import AboutUs2 from "../assets/mission.png";
import AboutUs3 from "../assets/values.png";

const Vision = () => {
  return (
    <div className="vision-container">
      <div>
        <h1 className="vision-heading">Vision, Mission And Values </h1>
        <hr />

        <div className="image-content-section-vision">
          <div className="container">
            <div className="row row-1">
              <div className="col-md-6 order-md-1">
                <img
                  src={AboutUs1}
                  alt="Left Image"
                  className="about-image-vision"
                />
              </div>
              <div className="col-md-6 order-md-2">
                <div className="content">
                  <h2 className="content-heading-vision">VISION</h2>
                  <p className="content-para">
                    Founded in 2001-02 under the visionary leadership of Shri
                    Ram Babaraoji Jogdand, Mr. Chandrashekhar Haribhau More, and
                    Mr. Bharat Nathuji Saroda, GEETAI Humankind Development
                    Trust envisions a future where sustainable social
                    development transforms lives through its core focus areas:
                    Education, Health, Social Welfare, Agriculture, and
                    Spiritual Upliftment (EHSAS).
                  </p>
                  <p className="content-para">
                    Our commitment to empowering communities stems from our core
                    values of integrity and a results-driven approach. By
                    operating several initiatives with internal funding, we
                    maintain independence and ensure long-term viability. Our
                    vision is to foster a self-reliant and impactful
                    organization that champions service to humanity and
                    sustainable development.
                  </p>
                  <p className="content-para">
                    We aspire to create a ripple effect of positive change,
                    inspiring other organizations and individuals to join in the
                    mission of holistic development. Through innovative
                    solutions and dedicated service, GEETAI aims to build a
                    resilient society where every individual has the opportunity
                    to thrive and contribute meaningfully to their community.
                  </p>
                </div>
              </div>
            </div>
            <div className="row row-1">
              <div className="col-md-6 order-md-2">
                <img
                  src={AboutUs2}
                  alt="Right Image"
                  className="about-image-vision"
                />
              </div>
              <div className="col-md-6 order-md-1">
                <div className="content missionContent">
                  <h2 className="content-heading-vision">MISSION</h2>
                  <p className="content-para">
                    GEETAI is dedicated to advancing education and agriculture
                    through its five schools and two agricultural colleges. Each
                    institution is committed to delivering high-quality
                    education and hands-on training, equipping students with the
                    knowledge and skills necessary for future success. Our
                    mission is to foster intellectual development and practical
                    expertise, effectively bridging the gap between academic
                    learning and real-world application. applications.
                  </p>
                  <p className="content-para">
                    Beyond formal education, GEETAI actively engages in outreach
                    programs designed to extend educational opportunities to
                    underserved communities. These initiatives aim to promote
                    lifelong learning and ensure that individuals from all
                    backgrounds have access to valuable resources and support.
                    Through our comprehensive approach, GEETAI strives to make a
                    lasting impact on both education and community development.
                  </p>
                  <p className="content-para">
                    GEETAI emphasizes the integration of modern agricultural
                    practices within its educational framework. By incorporating
                    hands-on training and practical experience into its
                    curriculum, GEETAI ensures that students and farmers alike
                    are well-versed in innovative farming techniques. Our
                    programs are designed to address contemporary challenges in
                    agriculture, equipping participants with the tools and
                    knowledge to enhance productivity and sustainability.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 order-md-1">
                <img
                  src={AboutUs3}
                  alt="Left Image"
                  className="about-image-vision"
                />
              </div>
              <div className="col-md-6 order-md-2">
                <div className="content">
                  <h2 className="content-heading-vision">VALUES</h2>
                  <p className="content-para">
                    GEETAI upholds a strong commitment to sustainable
                    agricultural practices, focusing on education and
                    environmental stewardship. Our values are reflected in our
                    dedication to: <br />
                    <b>1. Sustainability:</b> Promoting environmentally-friendly
                    farming techniques that enhance productivity while
                    protecting natural resources.
                    <br /> <b>2. Education and Empowerment:</b> Providing
                    comprehensive training programs that equip farmers with
                    modern techniques, soil health management, and effective
                    irrigation methods.
                    <br />
                    <b>3. Innovation and Research</b>: Collaborating with
                    agricultural experts and institutions to drive research and
                    development, ensuring access to the latest advancements and
                    solutions. <br />
                    <b> 4. Holistic Approach:</b> Integrating theoretical
                    knowledge with practical training to empower farmers,
                    improve yields, and strengthen livelihoods.
                    <br />
                    By embedding these values into our initiatives, GEETAI
                    strives to ensure a resilient and sustainable agricultural
                    sector for future generations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;

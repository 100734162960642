//admin/LoginPagee.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './auth/AuthProvider'; 
import './CSS/LoginPage.css';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { login } = useAuth(); 
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        if (username === 'admin' && password === 'admin@123' || username === 'Akash' && password === 'Akash@123') {
            try {
                await login(username, password); 
                navigate('/admin');
            } catch (error) {
                setError('Invalid username or password');
            }
        } else {
            setError('Invalid username or password');
        }
    };

    return (
        <div className="login-page-container">
            <div className="login-box">
                <h2 className="login-title">Admin Login</h2>
                {error && <p className="login-error-message">{error}</p>}
                <form onSubmit={handleLogin} className="login-form">
                    <div className="login-form-group">
                        <label htmlFor="username" className="login-label">Username</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            className="login-input"
                        />
                    </div>
                    <div className="login-form-group">
                        <label htmlFor="password" className="login-label">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="login-input"
                        />
                    </div>
                    <button type="submit" className="login-button">Login</button>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;


















import React from "react";
import "./CSS/Gallery.css";
import GalleryModal from "../components/GalleryModal";
import { Carousel } from "react-bootstrap";
import sampleVideo from "../assets/intro.mp4";
import g1 from "../assets/g1.jpg";
import g2 from "../assets/g2.jpg";
import g3 from "../assets/g3.jpg";
import g4 from "../assets/g4.jpg";
import g5 from "../assets/g5.jpg";
import g6 from "../assets/g6.jpg";
import g7 from "../assets/g7.jpg";
import g8 from "../assets/g8.jpg";
import g9 from "../assets/g9.png";
import g10 from "../assets/g10.png";
import g11 from "../assets/g11.png";
import g12 from "../assets/g12.png";
import g13 from "../assets/g13.png";
import g14 from "../assets/g14.png";
import g15 from "../assets/g15.png";
import g16 from "../assets/g16.png";
import g17 from "../assets/g17.png";
import activity1 from "../assets/activity1.jpg";
import activity2 from "../assets/activity2.jpg";

const Gallery = () => {
  const gImages = [
    g1,
    g2,
    g3,
    g4,
    g5,
    g6,
    g7,
    g8,
    g9,
    g10,
    g11,
    g12,
    g13,
    g14,
    g15,
  ];
  
  return (
    <div className="gallery-container">
      <div className="gallery-container-2">
        <div className="video-container">
          <video controls>
            <source src={sampleVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="content-container">
          <h2>Geetai Trust</h2>
          <p>
            "Geetai Humankind Development Trust is Non Government Organization
            working for rural development since last 19 years having the broad
            VISION in the field of Education, Health, Social, agriculture &
            Spiritual and with the mission “Build institution for mankind
            development through proper support and training to achieve sustainable
            socio-economic responsibilities.”
          </p>
          <a href="https://www.youtube.com/channel/UCnC7S3fM7LBT8gr-zURxHXw" target="_blank" rel="noopener noreferrer">
            Click Here More Videos (GEETAI TRUST)
          </a>
        </div>
      </div>
      <hr />
      <GalleryModal images={gImages} />
      <hr />
      <Carousel className="activity-slider">
        <Carousel.Item>
          <img className="d-block w-100" src={activity1} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={activity2} alt="Second slide" />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Gallery;

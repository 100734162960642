import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import './CSS/AdminSponsorship.css';

const AdminSponsorship = () => {
    const [sponsorships, setSponsorships] = useState([]);
    const [editing, setEditing] = useState(null);
    const [searchQuery, setSearchQuery] = useState('')
    const [paymentModeFilter, setPaymentModeFilter] = useState('all');
    const [dateRange, setDateRange] = useState({ start: '', end: '' });

    useEffect(() => {
        axios.get('http://localhost:5000/admin/sponsorships')
            .then(response => setSponsorships(response.data))
            .catch(error => console.error('Error fetching sponsorship data:', error));
    }, []);

    const handleDelete = (id) => {
        axios.delete(`http://localhost:5000/admin/sponsorships/${id}`)
            .then(() => setSponsorships(sponsorships.filter(sponsorship => sponsorship.id !== id)))
            .catch(error => console.error('Error deleting sponsorship:', error));
    };

    const handleEdit = (id) => {
        setEditing(id);
    };

    const handleUpdate = (updatedSponsorship) => {
        setSponsorships(sponsorships.map(sponsorship =>
            sponsorship.id === updatedSponsorship.id ? updatedSponsorship : sponsorship
        ));
        setEditing(null);
    };

    const filteredSponsorships = sponsorships
        .filter(sponsorship => 
            (searchQuery === '' || 
                sponsorship.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                sponsorship.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
                sponsorship.mobileNumber.toLowerCase().includes(searchQuery.toLowerCase()))
            &&
            (paymentModeFilter === 'all' || sponsorship.paymentMode === paymentModeFilter)
            &&
            (dateRange.start === '' || dateRange.end === '' || 
            (new Date(sponsorship.createdAt) >= new Date(dateRange.start) && new Date(sponsorship.createdAt) <= new Date(dateRange.end)))
        );

    return (
        <div className="admin-sponsorship">
                    <div className="header-section">
                    <h2 className="admin-membership-heading">Sponsorship</h2>
                    <input
                        type="text"
                        placeholder="Search..."
                        className="search-bar"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)} 
                    />
                       <div className="filter-section">
                        <Form.Group controlId="paymentModeFilter">
                            <Form.Label>Payment Mode</Form.Label>
                            <Form.Control 
                                as="select" 
                                value={paymentModeFilter} 
                                onChange={(e) => setPaymentModeFilter(e.target.value)}
                            >
                                <option value="all">All</option>
                                <option value="online">Online</option>
                                <option value="cash">Cash</option>
                                <option value="cheque">Cheque</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="dateRange">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control 
                            
                                type="date" 
                                placeholder="Start Date"
                                value={dateRange.start}
                                onChange={(e) => setDateRange({ ...dateRange, start: e.target.value })}
                            />
                          </Form.Group>
                          <Form.Group controlId="dateRange">
                             <Form.Label>End Date</Form.Label>
                            <Form.Control 
                                type="date" 
                                placeholder="End Date"
                                value={dateRange.end}
                                onChange={(e) => setDateRange({ ...dateRange, end: e.target.value })}
                            />
                        </Form.Group>
              
                    </div>
                </div>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Remark</th>
                        <th>Payment Mode</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredSponsorships.map(sponsorship => (
                        <tr key={sponsorship.id}>
                            <td>{sponsorship.id}</td>
                            <td>{sponsorship.fullName}</td>
                            <td>{sponsorship.email}</td>
                            <td>{sponsorship.mobileNumber}</td>
                            <td>{sponsorship.sponsorshipAmount}</td>
                            <td>{sponsorship.status}</td>
                            <td>{sponsorship.remark}</td>
                            <td>{sponsorship.paymentMode}</td>
                            <td>{new Date(sponsorship.createdAt).toLocaleString()}</td>
                            <td>{new Date(sponsorship.updatedAt).toLocaleString()}</td>
                            <td className='button-td'>
                                <button onClick={() => handleEdit(sponsorship.id)} className="edit-btn">Edit</button>
                                <button onClick={() => handleDelete(sponsorship.id)} className="delete-btn">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {editing && <EditSponsorshipModal id={editing} onClose={() => setEditing(null)} onUpdate={handleUpdate} />}
        </div>
    );
};

const EditSponsorshipModal = ({ id, onClose, onUpdate }) => {
    const [sponsorship, setSponsorship] = useState(null);

    useEffect(() => {
        axios.get(`http://localhost:5000/admin/sponsorships/${id}`)
            .then(response => setSponsorship(response.data))
            .catch(error => console.error('Error fetching sponsorship data:', error));
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.put(`http://localhost:5000/admin/sponsorships/${id}`, sponsorship)
            .then(() => {
                onUpdate({ ...sponsorship, id });
                onClose();
            })
            .catch(error => console.error('Error updating sponsorship data:', error));
    };

    return (
        <Modal show onHide={onClose} className="edit-sponsorship-modal">
            <Modal.Header closeButton>
                <Modal.Title>Edit Sponsorship</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {sponsorship ? (
                    <Form onSubmit={handleSubmit} className="edit-form">
                        <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={sponsorship.fullName}
                                onChange={(e) => setSponsorship({ ...sponsorship, fullName: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                value={sponsorship.email}
                                onChange={(e) => setSponsorship({ ...sponsorship, email: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="formMobile">
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control
                                type="text"
                                value={sponsorship.mobileNumber}
                                onChange={(e) => setSponsorship({ ...sponsorship, mobileNumber: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="formAmount">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control
                                type="text"
                                value={sponsorship.sponsorshipAmount}
                                onChange={(e) => setSponsorship({ ...sponsorship, sponsorshipAmount: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="formStatus">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                as="select"
                                value={sponsorship.status}
                                onChange={(e) => setSponsorship({ ...sponsorship, status: e.target.value })}
                            >
                                <option value="success">Success</option>
                                <option value="pending">Pending</option>
                                <option value="rejected">Rejected</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formRemark">
                            <Form.Label>Remark</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={sponsorship.remark}
                                onChange={(e) => setSponsorship({ ...sponsorship, remark: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="formPaymentMode">
                            <Form.Label>Payment Mode</Form.Label>
                            <Form.Control
                                as="select"
                                value={sponsorship.paymentMode}
                                onChange={(e) => setSponsorship({ ...sponsorship, paymentMode: e.target.value })}
                            >
                                <option value="online">Online</option>
                                <option value="cash">Cash</option>
                                <option value="cheque">Cheque</option>
                            </Form.Control>
                        </Form.Group>
                        <div className="modal-footer">
                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                            <Button variant="secondary" onClick={onClose}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                ) : (
                    <p>Loading...</p>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AdminSponsorship;

// src/components/AdminHeader.js
import React from 'react';
import { Link } from 'react-router-dom';
import './CSS/AdminHeader.css';

const AdminHeader = () => {
    return (
        <header className="admin-header">
            <div className="admin-header-content">
                <h1 className='adminHeader-title'>Admin Panel</h1>
                <div className="admin-header-buttons">
                    <Link to="/admin/login" className="admin-button">Logout</Link>
                </div>
            </div>
        </header>
    );
};

export default AdminHeader;




import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../assets/Geetai Logo.png";
import { Link, useNavigate } from "react-router-dom";
import "./CSS/Header.css";

const Header = () => {
  const [fundingOpen, setFundingOpen] = useState(false);
  const [sustainableOpen, setSustainableOpen] = useState(false);
  const [socialOpen, setSocialOpen] = useState(false);
  const [eventsOpen, setEventsOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [sticky, setSticky] = useState(false);
  const navigate = useNavigate();

  const handleFundingEnter = () => setFundingOpen(true);
  const handleFundingLeave = () => setFundingOpen(false);

  const handleSustainableEnter = () => setSustainableOpen(true);
  const handleSustainableLeave = () => setSustainableOpen(false);

  const handleSocialEnter = () => setSocialOpen(true);
  const handleSocialLeave = () => setSocialOpen(false);

  const handleEventsEnter = () => setEventsOpen(true);
  const handleEventsLeave = () => setEventsOpen(false);

  const handleAboutEnter = () => setAboutOpen(true);
  const handleAboutLeave = () => setAboutOpen(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavLinkClick = () => {
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth < 992) {
      navbarToggle.click();
      closeDropdowns();
    }
  };

  const closeDropdowns = () => {
    setFundingOpen(false);
    setSustainableOpen(false);
    setSocialOpen(false);
    setEventsOpen(false);
    setAboutOpen(false);
  };

  return (
    <Navbar
      bg="light"
      variant="primary"
      expand="lg"
      className={`mb-3 text-primary navbar-full ${sticky ? "sticky" : ""}`}
    >
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img
              src={Logo}
              height="30"
              className="d-inline-block align-top logo"
              alt="Logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="main-navbar">
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <Link
                to="/"
                className="nav-link"
                onClick={() => {
                  navigate("/");
                  handleNavLinkClick();
                }}
              >
                Home
              </Link>
            </li>
            {/* <li className="nav-item">
                            <Link to="/about" className="nav-link" onClick={() => { navigate('/about'); handleNavLinkClick(); }}>About</Link>
                        </li> */}
            <NavDropdown
              title="Who We Are"
              id="about-dropdown"
              show={aboutOpen}
              onMouseEnter={handleAboutEnter}
              onMouseLeave={handleAboutLeave}
            >
              <NavDropdown.Item
                as={Link}
                to="/about/overview"
                onClick={() => {
                  handleNavLinkClick();
                }}
              >
                Overview
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/about/vision-mission-values"
                onClick={() => {
                  handleNavLinkClick();
                }}
              >
                Vision, Mission & Values
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/about/our-founders"
                onClick={() => {
                  handleNavLinkClick();
                }}
              >
                Our Trustees
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/about/pillars-of-geetai"
                onClick={() => {
                  handleNavLinkClick();
                }}
              >
                Our Vice Presidents
              </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/about/advisory-committee"
              onClick={() => {
                  handleNavLinkClick();
                }}
                >
              Our Advisory Committee
            </NavDropdown.Item>
                </NavDropdown>
            <NavDropdown
              title="Funding"
              id="funding-dropdown"
              className="funding"
              show={fundingOpen}
              onMouseEnter={handleFundingEnter}
              onMouseLeave={handleFundingLeave}
              renderMenuOnMount={false}
            >
              <NavDropdown.Item
                as={Link}
                to="/funding/donation"
                onClick={() => {
                  handleNavLinkClick();
                }}
              >
                Donation
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/funding/membership"
                onClick={() => {
                  handleNavLinkClick();
                }}
              >
                Membership
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/funding/sponsorship"
                onClick={() => {
                  handleNavLinkClick();
                }}
              >
                Sponsorship
              </NavDropdown.Item>
            </NavDropdown>
            {/* <NavDropdown
              title="Sustainable"
              id="sustainable-dropdown"
              show={sustainableOpen}
              onMouseEnter={handleSustainableEnter}
              onMouseLeave={handleSustainableLeave}
            >
              <NavDropdown.Item
                as={Link}
                to="/sustainable/college-of-agriculture"
                onClick={() => {
                  handleNavLinkClick();
                }}
              >
                College of Agriculture
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/sustainable/vedant-public-school"
                onClick={() => {
                  handleNavLinkClick();
                }}
              >
                Vedant Public School
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/sustainable/krushi-tantra-niketan"
                onClick={() => {
                  handleNavLinkClick();
                }}
              >
                Krushi Tantra Niketan
              </NavDropdown.Item>
            </NavDropdown> */}
            <NavDropdown
              title="Social"
              id="social-dropdown"
              show={socialOpen}
              onMouseEnter={handleSocialEnter}
              onMouseLeave={handleSocialLeave}
            >
              <NavDropdown.Item
                as={Link}
                to="/social/link-bharat"
                onClick={() => {
                  handleNavLinkClick();
                }}
              >
                Link Bharat & Skill Development
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/social/janadhar"
                onClick={() => {
                  handleNavLinkClick();
                }}
              >
                Janadhar And Self Sufficiant Village
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/social/tourism-development"
                onClick={() => {
                  handleNavLinkClick();
                }}
              >
                Tourism Development
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="Events"
              id="events-dropdown"
              show={eventsOpen}
              onMouseEnter={handleEventsEnter}
              onMouseLeave={handleEventsLeave}
            >
              <NavDropdown.Item
                as={Link}
                to="/events/registration"
                onClick={() => {
                  handleNavLinkClick();
                }}
              >
                Events Registration
              </NavDropdown.Item>
            </NavDropdown>
            <li className="nav-item">
              <Link
                to="/gallery"
                className="nav-link"
                onClick={() => {
                  navigate("/gallery");
                  handleNavLinkClick();
                }}
              >
                Gallery
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/contacts"
                className="nav-link"
                onClick={() => {
                  navigate("/contacts");
                  handleNavLinkClick();
                }}
              >
                Contacts
              </Link>
            </li>
          </ul>
          <Link
            to="/funding/donation"
            className="btn btn-success ms-auto donate-btn"
            onClick={() => {
              handleNavLinkClick();
            }}
          >
            Donate
          </Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;

import React, { useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';
import './CSS/Counter.css';

const Counter = () => {
  const [isVisible, setIsVisible] = useState(false);
  const counterRef = useRef(null);

  // Function to check if an element is in the viewport
  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  // Function to handle scroll event
  const handleScroll = () => {
    if (counterRef.current && isInViewport(counterRef.current)) {
      setIsVisible(true);
      // Remove the scroll event listener once the counters are visible
      window.removeEventListener('scroll', handleScroll);
    }
  };

  // Add scroll event listener when component mounts
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // Clean up by removing event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="counter-container">
      <div className="counter-item" ref={counterRef}>
        <h2 className="counter-value">
          <CountUp start={0} end={isVisible ? 6200 : 0} duration={3} separator="," suffix="+" />
        </h2>
        <p className="counter-label">Donations</p>
      </div>
      <div className="counter-item" ref={counterRef}>
        <h2 className="counter-value">
          <CountUp start={0} end={isVisible ? 5400 : 0} duration={3} separator="," suffix="+" />
        </h2>
        <p className="counter-label">Fund Raised</p>
      </div>
      <div className="counter-item" ref={counterRef}>
        <h2 className="counter-value">
          <CountUp start={0} end={isVisible ? 12000 : 0} duration={3} separator="," suffix="+" />
        </h2>
        <p className="counter-label">Count</p>
      </div>
      <div className="counter-item" ref={counterRef}>
        <h2 className="counter-value">
          <CountUp start={0} end={isVisible ? 3200 : 0} duration={3} separator="," suffix="+" />
        </h2>
        <p className="counter-label">Children</p>
      </div>
    </div>
  );
};

export default Counter;


import React, {useState} from "react";
import "./CSS/Events.css";
import flag from "../assets/indian.jpg";
import Card from "../components/Card";
import diwali from "../assets/v27.jpg";
import sankrant from "../assets/v30.jpg";
import repblic from "../assets/v18.jpg";
import sport from "../assets/f5.png";
import blood from "../assets/j4.png";
import EventRegistration from "../components/EventRegistration";

const Events = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    console.log("Show Modal Clicked");
    setShowModal(true);
  };
  const handleCloseModal = () => {
    console.log("Close Modal Clicked");
    setShowModal(false);
  };
  const cardsData = [
    {
      id: 1,
      imageUrl: diwali,
      title: "Diwali",
      description: "Bright Diwali card featuring colorful rangoli, glowing diyas, and warm festive greetings for joyful celebrations.",
    },
    {
      id: 2,
      imageUrl: blood,
      title: "Blood Donation",
      description: "Vibrant Makar Sankranti card with kites flying high, sugarcane, and joyous wishes for the festive season.",
    },
    {
      id: 3,
      imageUrl: repblic,
      title: "Republic Day",
      description: "Patriotic Republic Day card featuring the Indian flag, festive decorations, and heartfelt wishes for national pride.",
    },
    {
      id: 4,
      imageUrl: sport,
      title: "Sports Day",
      description: "Celebrate Sports Day with vibrant athletes, equipment, and motivational messages.",
    },
    {
      id: 5,
      imageUrl: diwali,
      title: "Diwali",
      description: "Bright Diwali card featuring colorful rangoli, glowing diyas, and warm festive greetings for joyful celebrations.",
    },
    {
      id: 6,
      imageUrl: sankrant,
      title: "Makar Sankranti",
      description: "Vibrant Makar Sankranti card with kites flying high, sugarcane, and joyous wishes for the festive season.",
    },
    {
      id: 7,
      imageUrl: repblic,
      title: "Republic Day",
      description: "Patriotic Republic Day card featuring the Indian flag, festive decorations, and heartfelt wishes for national pride.",
    },
    {
      id: 8,
      imageUrl: sport,
      title: "Sports Day",
      description: "Celebrate Sports Day with vibrant athletes, equipment, and motivational messages.",
    },
  ];

  return (
    <div className="events-container">
      {/* Banner Section */}
      <div className="event-section" style={{ backgroundImage: `url(${flag})` }}>
        <div className="event-content">
          <h2 className="event-heading">
            Republic Day <br /> Event 
          </h2>
          <button className="btn btn-primary" onClick={handleShowModal}>Register Now</button>
        </div>
      </div> <hr/>

      {/* Cards Section */}
      <div className="cards-section">
        <div className="card-container">
          {cardsData.map((card) => (
            <Card key={card.id} imageUrl={card.imageUrl} title={card.title} description={card.description}  /> 
          ))}
        </div>
      </div> <hr/>
      <EventRegistration show={showModal} handleClose={handleCloseModal} />
    </div>
  );
};

export default Events;

import React, { useState } from "react";
import "./CSS/Sponsorship.css";
import feedOne from "../assets/feedOne.jpg";
import photo1 from "../assets/const.jpg";
import photo2 from "../assets/COA51.jpeg";
import photo3 from "../assets/pool.jpg";
import photo4 from "../assets/v2.jpg";
import photo5 from "../assets/v6.jpg";
import photo6 from "../assets/v8.jpeg";
import MembershipForm from '../components/MembershipForm';

const Sponsorship = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    console.log('Show Modal Clicked');
    setShowModal(true);
  };
  const handleCloseModal = () => {
    console.log('Close Modal Clicked');
    setShowModal(false);
  };


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const images = [photo1, photo2, photo3, photo4, photo5, photo6];
  const imageHeadings = [
    "Construction",
    "Library",
    "Swimming Pool",
    "Laboratories",
    "Play Ground",
    "Auditorium",
  ];

  const openModal = (index) => {
    setCurrentImage(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const previousImage = () => {
    setCurrentImage((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const nextImage = () => {
    setCurrentImage((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };
  return (
    <div className="sponsorship-container">
      <div className="sponsor-div">
      <h1 className="quate">"If you can't feed a hundred people, <br/>  then just feed one"</h1>
      <div className="sponsor-btn">
      <button className="btn btn-primary" onClick={handleShowModal}>
            Become a Sponsor
          </button></div>
          </div>
      <hr />

      <div className="gallery">
        {images.map((image, index) => (
          <div
            key={index}
            className="gallery-item"
            onClick={() => openModal(index)}
          >
            <img src={image} alt={imageHeadings[index]} />
            <h3 className="gallery-heading">{imageHeadings[index]}</h3>
          </div>
        ))}
      </div>

      <hr />
      <div className="sponsorship-content">
        <div className="sponsorship-heading">
          <h3>SPONSORSHIP</h3>
        </div>
        <p className="sponsorship-para">
          The Geetai Humankind Development Trust Pune was established in 2001 on
          the strength of strong will and confidence, keeping in view the ideals
          of Vinoba Bhave's Ajramar Geetai and Vinoba Bhave's vision of
          humanity. After the idea that nothing is impossible if the idea is
          with action, the organization started implementing various activities.
          The Geetai Humankind Development Trust has been working for the last
          11-15 years in urban and rural areas mainly in five areas namely
          education, health, social, agriculture and spiritual. The organization
          has a hand in implementing new ventures. These include various
          innovative initiatives such as childcare and adventure camps, farmer
          guidance, agri-tourism, women empowerment, Whole Village Development
          (Digital Gao), Saraswati Agrotech.{" "}
        </p>
        <p className="sponsorship-para">
          The Geetai Humankind Development Trust is working to increase social
          commitment to society by rejecting values that are being destroyed in
          the current age of modern technology, etiquette, enhancing students'
          thinking ability, determination and rejecting loneliness. You are well
          aware of the various initiatives undertaken under this trust. We have
          seen an ascending graph of the Trust's performance in five areas:
          educational, health, social, agricultural and spiritual.{" "}
        </p>
        <p className="sponsorship-para">
          The Vedanta Public School was established by the Geetai Humankind
          Development Trust with the objective of inculcating good values in the
          new generation, inculcating a new generation of capable, ideological
          and modern thinkers. Today's students will be the citizens of
          tomorrow's progressive country and the organization focuses on
          imparting quality education through various branches of these schools
          so that their leadership qualities can be properly developed, students
          with character can be developed and their personality can be
          holistically developed through education. In addition to the academic
          development of the students in these schools, more and more emphasis
          is placed on the physical, educational, social, spiritual and mental
          development of the students. Various activities are also implemented.{" "}
        </p>
        <table className="sponsorship-table">
          <thead>
            <tr className="table-row">
              <th>SR NO</th>
              <th>Category</th>
              <th>Pre-Primary</th>
              <th>Primary</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="column-1">1</td>
              <td className="column-1">Tution Fees</td>
              <td className="column-1">6,000</td>
              <td className="column-1">10,000</td>
            </tr>
            <tr>
              <td className="column-1">2</td>
              <td className="column-1">School Kit</td>
              <td className="column-1">2,500</td>
              <td className="column-1">2,800</td>
            </tr>
            <tr>
              <td className="column-1">3</td>
              <td className="column-1">Activities</td>
              <td className="column-1">1,500</td>
              <td className="column-1">5,700</td>
            </tr>
            <tr>
              <td className="column-1">4</td>
              <td className="column-1">Complete Package</td>
              <td className="column-1">10,000</td>
              <td className="column-1">18,500</td>
            </tr>
          </tbody>
        </table>{" "}
        <br />
        Since the establishment of the school (since 2010) till date, in just 7
        years, a total of 2000 students from urban and rural areas have been
        imparted quality education. However, we are proposing sponsorship to our
        school so that their education is not disrupted due to poor financial
        condition of the students studying in the school. Members who wish to
        spend on the educational work of poor students in Vedanta Public School
        run by this trust for the purpose of maintaining social commitment can
        help in the form of sponsorship. The details in this context are as
        follows: <br />
        However, we look forward to your suggestions and ideas on all of the
        above. Your suggestions and ideas are invaluable to us. You should
        actively participate in various activities of Geetai Humankind
        Development Trust and contribute to our valuable work. And lead us on
        the right path.
        <p className="sponsorship-para">
          You can pay a sponsorship by filling out a sponsorship form to pay for
          tuition fees, school supplies, various activities, or all categories
          combined. You can also help one or more students financially in the
          form of sponsorship. For this you can get tax relief under 80G. Bank
          Details: Issued in the name of Cheque Geetai Humankind Development
          Trust, Pune.
        </p>
        <p className="sponsorship-para">
          Bank details for RTGS: <br />
          Bank Name: State Bank of India. <br />
          Branch: Waraje Malwadi
          <br /> IFSC Code: SBIN0011701
          <br /> Account Number: 31167659452
          <br /> Yours faithfully, <br />
          Geetai Humankind Development Trust, Pune
        </p>

      </div>
      <MembershipForm show={showModal} handleClose={handleCloseModal} />
    </div>
  );
};

export default Sponsorship;

import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Counter from '../components/Counter';
import MemberFormModal from '../components/MemberFormModal';
import './CSS/About.css';
import AboutUs1 from '../assets/about_us5.png';
import AboutUs2 from '../assets/about_us8.png';
import AboutUs3 from '../assets/about_us9.png';
import humanity from '../assets/humanity.jpg';
import dept1 from '../assets/himmat_jogdand.png';
import dept2 from '../assets/rajesh_ghuge.png';
import dept3 from '../assets/ashish_dahatonde.png';

const About = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    console.log('Show Modal Clicked');
    setShowModal(true);
  };
  const handleCloseModal = () => {
    console.log('Close Modal Clicked');
    setShowModal(false);
  };

  return (
    <div className="about-container">
      {/* Humanity Section */}
      <div
        className="humanity-section"
        style={{ backgroundImage: `url(${humanity})` }}
      >
        <div className="humanity-content">
          <h2 className="humanity-heading">
            Let's Change The World <br /> With Humanity
          </h2>
          <button className="btn btn-primary2" onClick={handleShowModal}>
            Become a Member
          </button>
        </div>
      </div>
      {/* <hr className='hr'/> */}

      {/* GEETAI Departments Section */}
      <div className="geetai-dept">
        <div className="container">
          <h2 className="section-heading">GEETAI DEPARTMENTS</h2>
          <div className="row">
            {/* Department Card 1 */}
            <div className="col-md-4">
              <div className="dept-card">
                <img
                  src={dept1}
                  className="card-img-top"
                  alt="Agriculture Department"
                />
                <div className="card-body">
                  <p className="card-text">
                    Mr. Himmat M. Jogdand
                    <br />
                    Vice President, Dept Of Agriculture
                    <br />
                    Phone: +91-9822872039
                    <br />
                    Email: hmj@ramelex.com
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 ">
              <div className="dept-card">
                <img
                  src={dept2}
                  className="card-img-top"
                  alt="Agriculture Department"
                />
                <div className="card-body">
                  <p className="card-text">
                    Mr.Rajesh R.Ghuge
                    <br />
                    Vice President,Dept Of Social Affairs
                    <br />
                    Phone: +91-9011034355 / 9921683676
                    <br />
                    Email: rajeshghuge72@gmail.com
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="dept-card">
                <img
                  src={dept3}
                  className="card-img-top"
                  alt="Agriculture Department"
                />
                <div className="card-body">
                  <p className="card-text">
                    Mr.Ashish S.Dahatonde
                    <br />
                    Vice President,Dept Of Education
                    <br />
                    Phone: +91-9130095161
                    <br />
                    Email: vpedu@geetai.org / ashish@geetai.org
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <hr className="hr" /> */}
      <div className="about">
      <h1 className="about-heading">About Geetai</h1>
      <p className="about-text">
        GEETAI HUMANKIND DEVELOPMENT TRUST (GHKDT) is a distinguished
        non-governmental organization dedicated to enhancing rural development
        over the past 21 years. With a steadfast commitment to societal
        progress, GHKDT operates across multiple domains including Education,
        Health, Social Welfare, Agriculture, and Spiritual Development. Through
        its acronym E.H.S.A.S., GHKDT emphasizes rigorous Research and
        Development (R&D) in each area, ensuring comprehensive training and
        support programs that empower communities at grassroots levels.
      </p>
      <p className="about-text">
        In the realm of Education, GHKDT strives to bridge educational
        disparities by fostering learning environments that equip individuals
        with essential knowledge and skills. This initiative not only empowers
        youth but also empowers adults, promoting lifelong learning and
        educational advancement. Health remains a cornerstone of GHKDT's
        mission, where initiatives range from providing essential healthcare
        services to organizing health camps and conducting health awareness
        campaigns. These efforts are pivotal in improving overall well-being and
        reducing health inequalities across rural communities.
      </p>
      <p className="about-text">
        Through its enduring commitment and holistic approach, GHKDT continues
        to catalyze positive change, empowering individuals and communities to
        achieve sustainable socio-economic development and contribute
        meaningfully to society's well-being.
      </p>
      <p className="focus-text">
        Focus Of GHKDT On Various Important Issues Through (E.H.S.A.S.):
      </p>
      <ol className="about-list">
        <li>Education Training (R & D)</li>
        <li>Health Training (R & D)</li>
        <li>Social Training (R & D)</li>
        <li>Agriculture Training (R & D)</li>
        <li>Spiritual Training (R & D)</li>
      </ol>
      </div>
      {/* <hr className='hr'/> */}

      {/* Alternating Image and Content Sections */}
      <div className="image-content-section">
        <div className="container">
          <div className="row row-1">
            <div className="col-md-6 order-md-1">
              <img src={AboutUs1} alt="Left Image" className="about-image" />
            </div>
            <div className="col-md-6 order-md-2">
              <div className="content">
                <h2 className="content-heading">
                  SUSTAINABLE FUNDED ACTIVITIES
                </h2>
                <p className="content-para">
                  GEETAI Humankind Development Trust, established in 2001-02
                  under the guidance of Shri. Ram Babaraoji Jogdand, Mr.
                  Chandrashekhar Haribhau More, and Mr. Bharat Nathuji Saroda,
                  is committed to sustainable social development across five
                  crucial areas: Education, Health, Social Welfare, Agriculture,
                  and Spiritual upliftment (EHSAS). emphasizing empowerment
                  through service to humanity.
                </p>
                <p className="content-para">
                  Driven by integrity and a task-oriented mindset, GEETAI is
                  dedicated to fulfilling its objectives sustainably. It
                  operates several initiatives without external donations,
                  relying on internal funding from its leadership. This
                  financial prudence ensures the organization's independence and
                  long-term viability in its endeavors.
                </p>
                <p className="content-para">
                  In the education sector, GEETAI manages five schools and two
                  agricultural colleges, providing quality education and
                  practical training. The trust's holistic approach includes
                  research, awareness, and skill development, ensuring impactful
                  and sustainable outcomes in the communities it serves.
                </p>
              </div>
            </div>
          </div>
          <div className="row row-1">
            <div className="col-md-6 order-md-2">
              <img src={AboutUs2} alt="Right Image" className="about-image" />
            </div>
            <div className="col-md-6 order-md-1">
              <div className="content">
                <h2 className="content-heading">
                  EDUCATIONAL PROJECTS & OUTREACH
                </h2>
                <p className="content-para">
                  GEETAI has established five schools and two agricultural
                  colleges under its umbrella. Each educational institution
                  focuses on providing quality education and practical training,
                  ensuring that students are well-prepared for their future.
                  Through these schools and colleges, GEETAI aims to nurture
                  intellectual growth and practical skills, bridging the gap
                  between theoretical knowledge and real-world applications.
                </p>
                <p className="content-para">
                  In addition to formal education, GEETAI conducts various
                  outreach programs. These programs are designed to extend
                  educational opportunities beyond the classroom, reaching out
                  to underserved communities and promoting lifelong learning.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 order-md-1">
              <img src={AboutUs3} alt="Left Image" className="about-image" />
            </div>
            <div className="col-md-6 order-md-2">
              <div className="content">
                <h2 className="content-heading">AGRICULTURE INITIATIVES</h2>
                <p className="content-para">
                  In the agricultural sector, GEETAI focuses on sustainable
                  farming practices. The organization operates training programs
                  that educate farmers on modern techniques, soil health,
                  irrigation methods, and crop management. These initiatives aim
                  to increase productivity while preserving the environment.
                </p>
                <p className="content-para">
                  GEETAI also collaborates with agricultural experts and
                  institutions to conduct research and development projects.
                  These projects explore innovative solutions to agricultural
                  challenges, ensuring that farmers have access to the latest
                  advancements in the field.
                </p>
                <p className="content-para">
                  By integrating education with hands-on training, GEETAI
                  empowers farmers to adopt sustainable practices, enhance their
                  yields, and improve their livelihoods. This holistic approach
                  ensures that the agricultural sector remains resilient and
                  sustainable for future generations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <hr /> */}

      {/* Counter Component */}
      <Counter />
      {/* Modal */}
      <MemberFormModal show={showModal} handleClose={handleCloseModal} />
    </div>
  );
};

export default About;

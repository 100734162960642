import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

const EventRegistration = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    mobileNumber: '',
    occupation: '',
    currentAddress: '',
    permanentAddress: '',
    pincode: '',
    geetaiMember: 'no',
  });

  const handleChange = (e) => {
    const { id, value, type, name } = e.target;
    setFormData({
      ...formData,
      [type === 'radio' ? name : id]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    fetch('http://localhost:5000/send-event-registration-form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.text())
      .then((data) => {
        console.log('Success:', data);
        alert('Form submitted successfully!');
        handleClose();
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Error submitting form. Please try again.');
      });
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Republic Day Event Registration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="fullName" className="form-label">Name *</label>
            <input
              type="text"
              className="form-control"
              id="fullName"
              placeholder="Enter your full name"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="mobileNumber" className="form-label">Mobile Number *</label>
            <input
              type="tel"
              className="form-control"
              id="mobileNumber"
              placeholder="Enter your phone number"
              value={formData.mobileNumber}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email" className="form-label">Email *</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="occupation" className="form-label">Occupation</label>
            <input
              type="text"
              className="form-control"
              id="occupation"
              placeholder="Enter your occupation"
              value={formData.occupation}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="currentAddress" className="form-label">Current Address</label>
            <textarea
              className="form-control"
              id="currentAddress"
              rows="2"
              placeholder="Enter your current address"
              value={formData.currentAddress}
              onChange={handleChange}
            ></textarea>
          </div>

          <div className="form-group">
            <label htmlFor="permanentAddress" className="form-label">Permanent Address</label>
            <textarea
              className="form-control"
              id="permanentAddress"
              rows="2"
              placeholder="Enter your permanent address"
              value={formData.permanentAddress}
              onChange={handleChange}
            ></textarea>
          </div>

          <div className="form-group">
            <label htmlFor="pincode" className="form-label">Pincode</label>
            <input
              type="text"
              className="form-control"
              id="pincode"
              placeholder="Enter pincode"
              value={formData.pincode}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label className="form-label">Geetai Member</label>
            <div>
              <input
                type="radio"
                id="geetaiMemberYes"
                name="geetaiMember"
                value="yes"
                checked={formData.geetaiMember === 'yes'}
                onChange={handleChange}
              />
              <label htmlFor="geetaiMemberYes" className="ml-2 mr-4">Yes</label>
              <input
                type="radio"
                id="geetaiMemberNo"
                name="geetaiMember"
                value="no"
                checked={formData.geetaiMember === 'no'}
                onChange={handleChange}
              />
              <label htmlFor="geetaiMemberNo" className="ml-2">No</label>
            </div>
          </div>

          <div className="text-center">
            <button type="submit" className="btn btn-primary">Submit</button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EventRegistration;

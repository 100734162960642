import React, { useState } from "react";
import "./CSS/LinkBharat.css";
import { Carousel } from "react-bootstrap";
import humanity from "../assets/humanity.jpg";
import BannerImage1 from "../assets/activity1.jpg";
import BannerImage2 from "../assets/activity2.jpg";
import l1 from "../assets/l1.jpg";
import l2 from "../assets/l2.jpg";
import l3 from "../assets/l3.jpeg";
import l4 from "../assets/l4.jpeg";
import l5 from "../assets/l5.jpg";
import MemberFormModal from "../components/MemberFormModal";

const LinkBharat = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    console.log("Show Modal Clicked");
    setShowModal(true);
  };
  const handleCloseModal = () => {
    console.log("Close Modal Clicked");
    setShowModal(false);
  };
  return (
    <div className="linkBharat-container">
      <div className="slider-div">
        {/* Slider */}
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100 slide1"
              src={BannerImage1}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={BannerImage2}
              alt="Second slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>
      <hr />
      <h3 className="linkBharat-heading">LINK BHARAT</h3> <br />
      <span className="span">" An Enterpreneur Is A Doer,Not A Dreamer. "</span>
      <br />
      <div className="linkBharat-para">
        AS CAPITALIST WE ARE GOING TO PROVIDE NEEDY SERVICES TO CONSUMER SOCIETY
        WITH CLUB OF GENUINE ENTREPRENEUR/ ESTABLISHMENT
      </div>
      <div>
        <ol className="order_list">
          <li>
            ETHIC INDIA IS UNIQUE PLATFORM FOR SOCIAL ENTREPRENEURS WHO ARE
            PASSIONATE IN QUALITY, COMMITMENT AND THEIR VALUES.
          </li>
          <li>
            ITS PLATFORM FOR THOSE WHO BELIEVES THAT THEY AS AN INDIVIDUAL CAN
            BRING CHANGE IN SOCIETY.
          </li>
          <li>
            ITS PLATFORM FOR THOSE WHO THINKS THAT THEY CAN BRING A CHANGE IN
            LIFE OF ETHICAL BUSINESS PEOPLE.
          </li>
        </ol>
      </div>
      <div>
        <img src={l1} alt="Image" className="image" />
      </div>{" "}
      <hr />
      <h3 className="linkBharat-heading">SKILL DEVELOPMENT.</h3>
      <img src={l2} alt="Image" className="image2" />
      <div className="image-content">
        <img src={l3} alt="Vedant School" className="image-2" />
        <img src={l4} alt="Vedant School" className="image-2" />
      </div>
      <img src={l5} alt="Vedant School" className="image3" /> <hr />
      <div
        className="humanity-section"
        style={{ backgroundImage: `url(${humanity})` }}
      >
        <div className="humanity-content">
          <h2 className="humanity-heading">
            Let's Change The World <br /> With Humanity
          </h2>
          <button className="btn btn-primary" onClick={handleShowModal}>
            Become a Member
          </button>
        </div>
      </div>
      <MemberFormModal show={showModal} handleClose={handleCloseModal} />
    </div>
  );
};

export default LinkBharat;

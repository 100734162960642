import React, { useState } from "react";
import "./CSS/KrushiTantra.css";
import GalleryModal from "../components/GalleryModal";
import MemberFormModal from '../components/MemberFormModal';
import humanity from "../assets/humanity.jpg";
import college from "../assets/college.png";
import k1 from "../assets/k1.png";
import k2 from "../assets/k2.png";
import k3 from "../assets/k3.png";
import k4 from "../assets/k4.png";
import k5 from "../assets/k5.png";
import k6 from "../assets/k6.png";
import k7 from "../assets/k7.png";
import k8 from "../assets/k8.png";
import k9 from "../assets/k9.png";
import k10 from "../assets/k10.png";
import k11 from "../assets/k11.png";
import k12 from "../assets/k12.png";
import k13 from "../assets/k13.png";
import k14 from "../assets/k14.png";
import k15 from "../assets/k15.png";

const KrushiTantra = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    console.log('Show Modal Clicked');
    setShowModal(true);
  };
  const handleCloseModal = () => {
    console.log('Close Modal Clicked');
    setShowModal(false);
  };
  const Irrigation = [k1, k2, k3];
  const Experimental = [k4, k5, k6, k7, k8, k9, k10, k11, k12, k13, k14, k15];
  return (
    <div className="krushi-container">
      <div>
        <img src={college} alt="Vedant School" className="image" />
      </div>
      <hr />
      <h3 className="vedant-heading">Krushi Tantra Niketan, Amkheda</h3>
      <div className="image-content">
        <div className="vedant-para">
          <p>
            " India is mainly an agriculture country and its economy is largely
            depends on agriculture. The farmer being important element of
            nation, Geetai decided to contribute for concrete work in
            agriculture sector and started Krushi Tantra Niketan at Amkheda,
            Dist. Washim. Our aim behind establishing this institute is to
            empower students in becoming self employed by generating agriculture
            Business. Some of the key points at KTN are :- 1. Organic Farming 2.
            Seed Germination 3. Farm Produce Process 4. Animal Husbandry and
            Dairy 5. Fruit Processing Nursery 6. Seed Production Agri-Tourism 7.
            Research Agricultural Engineering ”
          </p>
        </div>
      </div>{" "}
      <hr />
      <h3 className="vedant-heading">Irrigation Facility & Plants</h3>
      <GalleryModal images={Irrigation} /> <hr />
      <h3 className="vedant-heading">
        Experimental Labs & Experimental Project
      </h3>
      <GalleryModal images={Experimental} /> <hr />
      <div className="humanity-section" style={{ backgroundImage: `url(${humanity})` }}>
        <div className="humanity-content">
          <h2 className="humanity-heading">
            Let's Change The World <br /> With Humanity
          </h2>
          <button className="btn btn-primary" onClick={handleShowModal}>Become a Member</button>
        </div>
      </div>
      <MemberFormModal show={showModal} handleClose={handleCloseModal} />
    </div>
  );
};

export default KrushiTantra;

import React from "react";
import "./CSS/Tourism.css";
import GalleryModal from "../components/GalleryModal";
import bg from "../assets/tourism.jpg";
import t1 from "../assets/t1.png";
import t2 from "../assets/t2.png";
import t3 from "../assets/t3.png";
import t4 from "../assets/l5.jpg";
import t5 from "../assets/l2.jpg";

const Tourism = () => {
  const images = [
    t1,
    t2,
    t3,]

  return (
    <div className="tourism-container">
      <div
        className="humanity-section"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="humanity-content">
          <h2 className="tourism-heading">
            We wander for distraction,but we wander for fulfilment. <br />
            Tourism development of washim district
          </h2>
            <h4 className="tourism-heading-2">
              IDENTIFY LOCATION OF WASHIM, WHICH PLACE HAVE TOURISM POTENTIAL
              AWARENESS & TRAINING OF VTD IMPLEMENTATION OF VTD FURTHER SUPPORT
            </h4>
        </div>
      </div> <hr/>
      <GalleryModal images={images} /> <hr/>
      <img src={t4} alt="Vedant School" className="image3" /> <hr/>
      <img src={t5} alt="Image" className="image2" />
    </div>
  );
};

export default Tourism;

// src/admin/PrivateRoute.js
import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from './auth/AuthProvider'; 

const PrivateRoute = ({ element: Element, ...rest }) => {
    const { isAuthenticated } = useContext(AuthContext);
    const location = useLocation();

    return isAuthenticated ? (
        <Element {...rest} />
    ) : (
        <Navigate to="/admin/login" state={{ from: location }} />
    );
};

export default PrivateRoute;














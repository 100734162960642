import React, { useState, useEffect, useRef } from "react";
import {
  Carousel,
  Card,
  Container,
  Row,
  Col,
  Tabs,
  Tab,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import sampleVideo from "../assets/intro.mp4";
import BannerImage1 from "../assets/slider2.jpg";
import BannerImage2 from "../assets/slider1.jpg";
import BannerImage3 from "../assets/slider2.jpg";
import Partition1 from "../assets/Kisaan Aatmnirbhar abhiyan_new1.jpg";
import Partition2 from "../assets/Kisaan_Aatmnirbhar_abhiyan_new2.jpg";
import activity1 from "../assets/activity1.jpg";
import activity2 from "../assets/activity2.jpg";
import placeholder1 from "../assets/2.png";
import placeholder2 from "../assets/3.png";
import placeholder3 from "../assets/4.png";
import placeholder4 from "../assets/6.png";
import placeholder5 from "../assets/5.png";
import AboutUs1 from "../assets/about_us5.png";
import AboutUs2 from "../assets/about_us8.png";
import AboutUs3 from "../assets/about_us9.png";
import o1 from "../assets/o1.jpg";
import o2 from "../assets/o2.jpg";
import map from "../assets/map.jpg";
import Counter from "../components/Counter";
import CarouselComponent from "../components/CarouselComponent";
import "./CSS/Home.css";
import { Link } from "react-router-dom";
import CountUp from "react-countup";

const initialImages = [
  placeholder1,
  placeholder2,
  placeholder3,
  placeholder4,
  placeholder5,
];

const Home = () => {
  const [key, setKey] = useState("information");
  const [isVisible, setIsVisible] = useState(false);
  const counterRef = useRef(null);

  // Function to check if an element is in the viewport
  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  // Function to handle scroll event
  const handleScroll = () => {
    if (counterRef.current && isInViewport(counterRef.current)) {
      setIsVisible(true);
      // Remove the scroll event listener once the counters are visible
      window.removeEventListener('scroll', handleScroll);
    }
  };

  // Add scroll event listener when component mounts
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // Clean up by removing event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="home-container">
      {/* slider banner */}
      <div className="slider-div">
        {/* Slider */}
        <Carousel interval={3000}>
          <Carousel.Item>
            <img
              className="d-block w-100 slide1"
              src={BannerImage1}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={BannerImage2}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={BannerImage3}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>

      {/* summer camp video */}
      <div className="gallery-container-2">
        <div className="video-container">
          <video controls>
            <source src={sampleVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="content-container">
          <h2>Geetai Trust</h2>
          <p>
            "Geetai Humankind Development Trust is Non Government Organization
            working for rural development since last 23 years having the broad
            VISION in the field of Education, Health, Social, agriculture &
            Spiritual and with the mission “Build institution for mankind
            development through proper support and training to achieve
            sustainable socio-economic responsibilities.”
          </p>
          <a
            href="https://www.youtube.com/channel/UCnC7S3fM7LBT8gr-zURxHXw"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click Here More Videos (GEETAI TRUST)
          </a>
        </div>
      </div>

      {/* outreach */}
      <div className="outreach-container" style={{ backgroundImage: `url(${map})`, backgroundSize: "cover" }}>
        {/* Heading */}
        <Container className="mt-4">
          <h1 className=" bot outreach-heading">Outreach</h1>
        </Container>

        {/* Cards */}
        <Container className="mt-4 outreach-cards">
          <Row xs={1} md={2} lg={4} className="g-4">
            <Col>
              <Card
                className="h-100 text-center custom-card custom-card-1 card card-body"
                style={{ backgroundImage: `url(${o1})` }}
              >
                <Card.Body ref={counterRef}>
                  <div className="icon-div">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      size="2x"
                      className="icon"
                    />
                  </div>
                  <h2 className="card-text counter-value">
                    <CountUp
                    className="countUp"
                      start={0}
                      end={isVisible ? 15 : 0}
                      duration={4}
                      separator=","
                      suffix=""
                    />
                    <br /> States
                  </h2>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card
                className="h-100 text-center custom-card custom-card-2"
                style={{ backgroundImage: `url(${o2})` }}
              >
                <Card.Body>
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    size="2x"
                    className="icon"
                  />
                  <h2 className="card-text counter-value">
                    <CountUp
                    className="countUp"
                      start={0}
                      end={isVisible ? 36 : 0}
                      duration={4}
                      separator=","
                      suffix=""
                    />
                    <br /> Total Districts including 56 Aspirational Districts
                  </h2>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card
                className="h-100 text-center custom-card custom-card-3"
                style={{ backgroundImage: `url(${o1})` }}
              >
                <Card.Body>
                  <div className="icon-div">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      size="2x"
                      className="icon"
                    />
                  </div>
                  <h2 className="card-text counter-value">
                    <CountUp
                    className="countUp"
                      start={0}
                      end={isVisible ? 1000 : 0}
                      duration={4}
                      separator=","
                      suffix=""
                    />{" "}
                    <br /> Villages
                  </h2>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card
                className="h-100 text-center custom-card custom-card-4"
                style={{ backgroundImage: `url(${o2})` }}
              >
                <Card.Body>
                  <div className="icon-div">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      size="2x"
                      className="icon"
                    />
                  </div>
                  <h2 className="card-text counter-value">
                    <CountUp
                    className="countUp"
                      start={0}
                      end={isVisible ? 4 : 0}
                      duration={4}
                      separator=","
                      suffix=""
                    />{" "}
                    <br />
                    Million Families
                  </h2>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Impact Stories */}
      <div className="image-content-section">
        <div className="container">
          <h1 className=" bot ourProgram-heading">Impact Stories</h1>
          <div className="row row-1">
            <div className="col-md-6 order-md-1">
              <img src={AboutUs1} alt="Left Image" className="about-image" />
            </div>
            <div className="col-md-6 order-md-2">
              <div className="content">
                <h2 className="content-heading">
                  SUSTAINABLE FUNDED ACTIVITIES
                </h2>
                <p className="content-para">
                  GEETAI Humankind Development Trust, established in 2001-02
                  under the guidance of Shri. Ram Babaraoji Jogdand, Mr.
                  Chandrashekhar Haribhau More, and Mr. Bharat Nathuji Saroda,
                  is committed to sustainable social development across five
                  crucial areas: Education, Health, Social Welfare, Agriculture,
                  and Spiritual upliftment (EHSAS). emphasizing empowerment
                  through service to humanity.
                </p>
                <p className="content-para">
                  Driven by integrity and a task-oriented mindset, GEETAI is
                  dedicated to fulfilling its objectives sustainably. It
                  operates several initiatives without external donations,
                  relying on internal funding from its leadership. This
                  financial prudence ensures the organization's independence and
                  long-term viability in its endeavors.
                </p>
                <p className="content-para">
                  In the education sector, GEETAI manages five schools and two
                  agricultural colleges, providing quality education and
                  practical training. The trust's holistic approach includes
                  research, awareness, and skill development, ensuring impactful
                  and sustainable outcomes in the communities it serves.
                </p>
              </div>
            </div>
          </div>
          <div className="row row-1">
            <div className="col-md-6 order-md-2">
              <img src={AboutUs2} alt="Right Image" className="about-image" />
            </div>
            <div className="col-md-6 order-md-1">
              <div className="content">
                <h2 className="content-heading">
                  EDUCATIONAL PROJECTS & OUTREACH
                </h2>
                <p className="content-para">
                  GEETAI has established five schools and two agricultural
                  colleges under its umbrella. Each educational institution
                  focuses on providing quality education and practical training,
                  ensuring that students are well-prepared for their future.
                  Through these schools and colleges, GEETAI aims to nurture
                  intellectual growth and practical skills, bridging the gap
                  between theoretical knowledge and real-world applications.
                </p>
                <p className="content-para">
                  In addition to formal education, GEETAI conducts various
                  outreach programs. These programs are designed to extend
                  educational opportunities beyond the classroom, reaching out
                  to underserved communities and promoting lifelong learning.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 order-md-1">
              <img src={AboutUs3} alt="Left Image" className="about-image" />
            </div>
            <div className="col-md-6 order-md-2">
              <div className="content">
                <h2 className="content-heading">AGRICULTURE INITIATIVES</h2>
                <p className="content-para">
                  In the agricultural sector, GEETAI focuses on sustainable
                  farming practices. The organization operates training programs
                  that educate farmers on modern techniques, soil health,
                  irrigation methods, and crop management. These initiatives aim
                  to increase productivity while preserving the environment.
                </p>
                <p className="content-para">
                  GEETAI also collaborates with agricultural experts and
                  institutions to conduct research and development projects.
                  These projects explore innovative solutions to agricultural
                  challenges, ensuring that farmers have access to the latest
                  advancements in the field.
                </p>
                <p className="content-para">
                  By integrating education with hands-on training, GEETAI
                  empowers farmers to adopt sustainable practices, enhance their
                  yields, and improve their livelihoods. This holistic approach
                  ensures that the agricultural sector remains resilient and
                  sustainable for future generations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cardsCarouselSlider">
        {/* Heading */}
        <Container className="mt-4">
          <h1 className=" bot ourProgram-heading">Our Programs</h1>
        </Container>
        <CarouselComponent images={initialImages} />
      </div>

      {/* Our Mission */}
      <div className="ourMission-div">
        <Container className="mt-4">
          <h1 className=" bot ourProgram-heading">Our Mission</h1>
        </Container>

        {/* Image and Tabs for Our Mission Content */}
        <Container className="mt-4">
          <Row className="align-items-center ourMission-row">
            <Col md={6}>
              <img
                src={Partition1}
                alt="Geetai Humankind Development Trust"
                className="section-img"
              />
            </Col>
            <Col md={6}>
              <div className="tabs-container">
                <Tabs
                  id="our-mission-tabs"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3 custom-tabs"
                >
                  <Tab
                    eventKey="information"
                    title="Information"
                    className="custom-tab"
                  >
                    <div className="tab-content">
                      <p className="ourMission-content">
                        Geetai Humankind Development Trust places education at
                        the forefront of its efforts, striving to deliver
                        quality learning opportunities to children and adults
                        alike. By implementing diverse educational programs and
                        initiatives, the Trust aims to close the educational gap
                        prevalent in rural areas, equipping individuals with
                        essential knowledge and skills for personal growth and
                        socio-economic advancement.
                      </p>
                      <p className="ourMission-content">
                        In the realm of health, the Trust is committed to
                        enhancing the overall well-being of rural communities.
                        This commitment manifests through the provision of
                        crucial healthcare services, the organization of health
                        camps, and vigorous health awareness campaigns. By
                        addressing pressing health issues and ensuring
                        accessibility to vital resources, the Trust
                        significantly elevates the quality of life in these
                        communities.
                      </p>
                      <p className="ourMission-content">
                        Social welfare remains a pivotal focus for Geetai
                        Humankind Development Trust, aimed at uplifting
                        marginalized and underprivileged segments of society.
                        Through targeted interventions and comprehensive social
                        programs, the Trust endeavors to improve living
                        standards, boost economic opportunities, and foster a
                        cohesive sense of community and belonging
                      </p>
                      <p className="ourMission-content">
                        Guided by its vision to build sustainable institutions
                        for mankind's development, Geetai Humankind Development
                        Trust remains steadfast in its mission to empower
                        individuals and communities, paving the way for a more
                        equitable and prosperous future. Recognizing agriculture
                        as the backbone of rural economies.
                      </p>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="mission"
                    title="Mission"
                    className="custom-tab"
                  >
                    <div className="tab-content">
                      <p className="ourMission-content">
                        Education is a cornerstone of Geetai Humankind
                        Development Trust's efforts. The organization aims to
                        provide quality education to children and adults alike,
                        ensuring that every individual has the opportunity to
                        learn and grow. Through various educational programs and
                        initiatives, the Trust works to bridge the gap in access
                        to education in rural areas, empowering individuals with
                        the knowledge and skills they need to improve their
                        lives.
                      </p>
                      <p className="ourMission-content">
                        In the field of health, the Trust is dedicated to
                        improving the overall well-being of rural communities.
                        This includes providing access to essential healthcare
                        services, organizing health camps, and promoting health
                        awareness. By addressing critical health issues and
                        ensuring that people have the resources they need to
                        stay healthy, the organization is making a tangible
                        impact on the quality of life in these communities.
                      </p>
                      <p className="ourMission-content">
                        Social welfare is another key area of focus for Geetai
                        Humankind Development Trust. The organization works to
                        uplift marginalized and underprivileged sections of
                        society, providing support and resources to help them
                        achieve a better standard of living. This includes
                        various social programs aimed at improving living
                        conditions, enhancing economic opportunities, and
                        fostering a sense of community and inclusion.
                      </p>
                      <p className="ourMission-content">
                        Agriculture, being the backbone of rural economies,
                        receives special attention from the Trust. The
                        organization provides training and support to farmers,
                        helping them adopt sustainable agricultural practices
                        that can lead to better yields and improved livelihoods.
                      </p>
                    </div>
                  </Tab>
                  <Tab eventKey="vision" title="Vision" className="custom-tab">
                    <div className="tab-content">
                      <p className="ourMission-content">
                        Guided by its vision to build sustainable institutions
                        for mankind's development, Geetai Humankind Development
                        Trust remains steadfast in its mission to empower
                        individuals and communities, paving the way for a more
                        equitable and prosperous future. Recognizing agriculture
                        as the backbone of rural economies. By training and
                        extending support to farmers, the Trust facilitates the
                        adoption of sustainable agricultural practices. This
                        initiative not only enhances crop yields but also
                        improvement of livelihoods within rural communities.
                      </p>
                      <p className="ourMission-content">
                        Through its holistic approach, Geetai Humankind
                        Development Trust addresses key pillars of development:
                        education, healthcare, social welfare, and agricultural
                        advancement. Education is prioritized to bridge the gap
                        in rural areas, providing essential skills and knowledge
                        for personal and socio-economic growth. Health
                        initiatives focus on improving well-being through
                        accessible healthcare services, health camps, and
                        awareness campaigns.
                      </p>
                      <p className="ourMission-content">
                        With a commitment to sustainability and community
                        empowerment, the Trust envisions creating lasting
                        impacts that transcend immediate benefits. By fostering
                        self-reliance and resilience in communities, Geetai
                        Humankind Development Trust aims to build a foundation
                        where individuals can thrive independently and
                        contribute positively to society. ensures that the
                        Trust's efforts today lay the groundwork for a
                        prosperous and inclusive tomorrow, where every
                        individual has the opportunity to lead a dignified and
                        fulfilling life.
                      </p>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Three Partitions */}
      <div className="three-partitions">
        <Container className="mt-4">
          <Row className="g-4">
            {/* First Partition: About Our Foundation */}
            <Col xs={12} md={6} lg={4}>
              <div className="partition partition-1">
                <h4 className="aboutOurFoundation">About Our Foundation</h4>
                <p className="homeNgo">
                  Non Government Organization (NGO) working for rural
                  development
                </p>
                <p>
                  Focus of GHKDT on various important issues through
                  (E.H.S.A.S.)
                </p>
                <ol className="order_list">
                  <li>Education Training (R & D)</li>
                  <li>Health Training (R & D)</li>
                  <li>Social Training (R & D)</li>
                  <li>Agriculture Training (R & D)</li>
                  <li>Spiritual Training (R & D)</li>
                </ol>
                <Link
                  to="/about"
                  className="btn btn-success ms-auto donate-btn"
                >
                  About us
                </Link>
              </div>
            </Col>

            {/* Second Partition: Image */}
            <Col xs={12} md={6} lg={4}>
              <div className="partition">
                <img src={Partition1} alt="Image 1" className="partition-img" />
              </div>
            </Col>

            {/* Third Partition: Image */}
            <Col xs={12} md={6} lg={4}>
              <div className="partition">
                <img src={Partition2} alt="Image 2" className="partition-img" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="social-events">
        {/* HEADING */}
        <Container className="mt-4 eventsForTrustDonation">
          <h1 className="outreach-heading bot">
            We Arrange Many Social Events For Trust Donations Like
          </h1>
        </Container>

        {/* Slider */}
        <Carousel className="activity-slider">
          <Carousel.Item>
            <img className="d-block w-100" src={activity1} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={activity2} alt="Second slide" />
          </Carousel.Item>
        </Carousel>
      </div>

      <div className="counter-div">
        {/* HEADING */}
        <Container className="mt-4 eventsForTrustDonation">
          <h1 className="ourState-heading bot">Our Statistics</h1>
        </Container>
        <Counter />
      </div>
    </div>
  );
};

export default Home;

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import './CSS/MembershipForm.css';

const MembershipForm = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    mobileNumber: '',
    email: '',
    sponsorshipAmount: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('http://localhost:5000/send-sponsorship-form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.text())
      .then((data) => {
        console.log('Success:', data);
        alert('Form submitted successfully!');
        handleClose();
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Error submitting form. Please try again.');
      });
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Membership Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          {/* Form fields */}
          <div className="form-group">
            <label htmlFor="fullName" className="form-label">Name Of Member *</label>
            <input
              type="text"
              className="form-control"
              id="fullName"
              name="fullName"
              placeholder="Enter your full name"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="mobileNumber" className="form-label">Mobile Number *</label>
            <input
              type="tel"
              className="form-control"
              id="mobileNumber"
              name="mobileNumber"
              placeholder="Enter your phone number"
              value={formData.mobileNumber}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email" className="form-label">Email Id *</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="sponsorshipAmount" className="form-label">Sponsorship Amount Rs *</label>
            <input
              type="number"
              className="form-control"
              id="sponsorshipAmount"
              name="sponsorshipAmount"
              placeholder="Enter Sponsorship Amount"
              value={formData.sponsorshipAmount}
              onChange={handleChange}
              required
            />
          </div>
          <div className="text-center">
            <button type="submit" className="btn btn-primary">Submit</button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default MembershipForm;

import React from "react";
import "./CSS/Founders.css";
import dept1 from "../assets/ram_sir.jpeg";
import dept2 from "../assets/Bharat_Sarode.jpeg";
import dept3 from "../assets/Chandrashekhar_more.jpeg";
import dept4 from "../assets/Manorama.jpg";
import dept5 from "../assets/Santosh.jpg";
import dept6 from "../assets/avi_sir.jpeg";

const Founders = () => {
  return (
    <div className="pillers">
      <div>
        <h1 className="pillers-heading">Our Trustees</h1>
        <hr />
        <div className="geetai-pillers">
          <div className="container">
            <div className="row">
              {/* Department Card 1 */}
              <div className="col-md-4">
                <div className="pillers-card">
                  <img
                    src={dept1}
                    className="card-img-top-pillers"
                    alt="Himmat M. Jogdand"
                  />
                  <div className="pillers-card-body">
                    <p className="pillers-card-text">
                      Mr. Ram Babaraoji Jogdand
                      <br />
                      Managing Trustee
                      <br />
                      Phone: +91-9822872010
                      <br/>
                      Email: ram@ramelex.com
                    </p>
                  </div>
                </div>
              </div>

              {/* Department Card 2 */}
              <div className="col-md-4">
                <div className="pillers-card">
                  <img
                    src={dept2}
                    className="card-img-top-pillers"
                    alt="Rajesh R. Ghuge"
                  />
                  <div className="pillers-card-body">
                    <p className="pillers-card-text">
                    Dr Bharat N. Sarode
                      <br />
                      Trustee
                      <br />
                      Phone: 9422027599
                      <br />
                      Email: drbharatsarode@gmail.com
                    </p>
                  </div>
                </div>
              </div>

              {/* Department Card 3 */}
              <div className="col-md-4">
                <div className="pillers-card">
                  <img
                    src={dept3}
                    className="card-img-top-pillers"
                    alt="Ashish S. Dahatonde"
                  />
                  <div className="pillers-card-body">
                    <p className="pillers-card-text">
                    Mr. Chandrashekhar H. More
                      <br />
                      Trustee
                      <br />
                      Phone: 9850918515
                      <br />
                      Email: chmore@hotmail.com
                    </p>
                  </div>
                </div>
              </div>

              {/* Department Card 4 */}
              <div className="col-md-4">
                <div className="pillers-card">
                  <img
                    src={dept4}
                    className="card-img-top-pillers"
                    alt="Ashish S. Dahatonde"
                  />
                  <div className="pillers-card-body">
                    <p className="pillers-card-text">
                    Mrs. Manorama M. Jogdand
                      <br />
                      Trustee
                      <br />
                      Phone: 9022931616
                      <br />
                      {/* Email: */}
                    </p>
                  </div>
                </div>
              </div>

              {/* Department Card 5 */}
              <div className="col-md-4">
                <div className="pillers-card">
                  <img
                    src={dept5}
                    className="card-img-top-pillers"
                    alt="Ashish S. Dahatonde"
                  />
                  <div className="pillers-card-body">
                    <p className="pillers-card-text">
                    Dr. Santosh B. Jogdand
                      <br />
                      Trustee
                      <br />
                      Phone: 9850567753
                      <br />
                      Email: planasst@ramelex.com
                    </p>
                  </div>
                </div>
              </div>

              {/* Department Card 6 */}
              <div className="col-md-4">
                <div className="pillers-card">
                  <img
                    src={dept6}
                    className="card-img-top-pillers"
                    alt="Ashish S. Dahatonde"
                  />
                  <div className="pillers-card-body">
                    <p className="pillers-card-text">
                    Mr. Avinash M. Jogdand
                      <br />
                      Chief executive officer
                      <br />
                      Phone: 9822872010
                      <br />
                      Email: ceo@geetai.org
                    </p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Founders;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPhone, faEnvelope, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
import footer from "../assets/footer.png";
import "./CSS/Footer.css";
import { Link } from 'react-router-dom';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      <footer className="footer">
        <div className="footer-logo">
          <img src={footer} alt="Logo" />
        </div>

        <div className="footer-section quick-links">
          <h5 className="footer-heading">QUICK LINKS</h5>
          <ul className="ul-links">
            <li>
              <Link to="/about/overview" className="links">About us</Link>
            </li>
            <li>
              <Link to="/contacts" className="links">Contacts us</Link> 
            </li>
            <li>
              <Link to="/funding/donation" className="links">Donation</Link>
            </li>
            <li>
              <Link to="/funding/membership" className="links">Membership</Link>
            </li>
            <li>
              <Link to="/funding/sponsorship" className="links">Sponsorship</Link>
            </li>
          </ul>
        </div>

        <div className="footer-section legal">
          <h5 className="footer-heading">LEGAL</h5>
          <ul>
            <li>
              <Link to="/terms-of-use" className="links">Terms of Use</Link>
            </li>
            <li>
              <Link to="/privacy-policy" className="links">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/cookie-policy" className="links">Cookie Policy</Link>
            </li>
          </ul>
        </div>

        <div className="footer-section contact-us">
          <h5 className="footer-heading">CONTACT US</h5>
          <div className="contact-item">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
            <p>
              C/o Ramelex House, S. No. 81/2, Dangat Industrial Estate, Shivane,
              NDA Road, Pune 411 023, Maharashtra.
            </p>
          </div>
          <div className="contact-item">
            <FontAwesomeIcon icon={faPhone} className="icon" />
            <p>+919822872010 / +918380073023</p>
          </div>
          <div className="contact-item">
            <FontAwesomeIcon icon={faEnvelope} className="icon" />
            <p>info@geetai.org</p>
          </div>
        </div>
      </footer>

      <div className="sub-footer">
        <div className="sub-footer-p">
          <p>@Geetai, All rights reserved</p>
        </div>
        <div className="footer-section social-icons">
          <div className="sub-footer-icon">
            <div className="social-icons-container">
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} className="social-icon" />
              </a>
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter} className="social-icon" />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} className="social-icon" />
              </a>
            </div>
          </div>
        </div>
        <button className="scroll-to-top" onClick={scrollToTop}>
          <FontAwesomeIcon icon={faArrowUp} />
        </button>
      </div>
    </div>
  );
};

export default Footer;

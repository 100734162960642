import React, { useState } from "react";
import "./CSS/Vedant.css";
import GalleryModal from "../components/GalleryModal";
import humanity from "../assets/humanity.jpg";
import vedant1 from "../assets/VedantSchool-Shivane.jpg";
import vedant2 from "../assets/1.jpg";
import vedant4 from "../assets/1.png";
import vedant5 from "../assets/10.jpg";
import vedant6 from "../assets/18.jpeg";
import vedant7 from "../assets/19.jpeg";
import vedant8 from "../assets/2.png";
import vedant9 from "../assets/20.jpeg";
import vedant10 from "../assets/24.jpeg";
import v1 from "../assets/v1.jpg";
import v2 from "../assets/v2.jpg";
import v3 from "../assets/v3.jpg";
import v4 from "../assets/v4.jpg";
import v5 from "../assets/v5.jpg";
import v6 from "../assets/v6.jpg";
import v7 from "../assets/v7.jpg";
import v8 from "../assets/v8.jpeg";
import v9 from "../assets/v9.jpg";
import v10 from "../assets/v10.jpg";
import v11 from "../assets/v11.jpg";
import v12 from "../assets/v12.jpg";
import v13 from "../assets/v13.jpg";
import v14 from "../assets/v14.jpg";
import v15 from "../assets/v15.jpg";
import v16 from "../assets/v16.jpg";
import v17 from "../assets/v17.jpg";
import v18 from "../assets/v18.jpg";
import v19 from "../assets/v19.jpg";
import v20 from "../assets/v20.jpg";
import v21 from "../assets/v21.jpg";
import v22 from "../assets/v22.jpg";
import v23 from "../assets/v23.jpg";
import v24 from "../assets/v24.jpg";
import v25 from "../assets/v25.jpg";
import v26 from "../assets/v26.jpg";
import v27 from "../assets/v27.jpg";
import v28 from "../assets/v28.jpg";
import v29 from "../assets/v29.jpg";
import v30 from "../assets/v30.jpg";
import MemberFormModal from '../components/MemberFormModal';

const Vedant = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    console.log('Show Modal Clicked');
    setShowModal(true);
  };
  const handleCloseModal = () => {
    console.log('Close Modal Clicked');
    setShowModal(false);
  };
  const images = [
    vedant1,
    vedant2,
    vedant7,
    vedant4,
    vedant5,
    vedant6,
    vedant8,
    vedant9,
    vedant10,
  ];
  const vImages = [
    v1,
    v7,
    v3,
    v4,
    v5,
    v6,
    v8,
    v9,
    v10,
    v11,
    v12,
    v13,
    v14,
    v15,
    v16,
    v17,
    v18,
    v19,
    v20,
    v21,
    v22,
    v23,
    v24,
    v25,
    v26,
    v27,
    v28,
  ];
  const vsImages = [
    v29,
    v30,
    v1,
    v2,
    v8,
    v14,
  ]

  return (
    <div className="vedant-container">
      <div>
        <img src={vedant1} alt="Vedant School" className="image" />
      </div>
      <hr />
      <h3 className="vedant-heading">VEDANT PUBLIC SCHOOL</h3>

      <div className="image-content">
        <img src={vedant2} alt="Vedant School" className="image-2" />
        <div className="vedant-para">
          <p>
            " Vedant Public school is working with a unique combination of CBSE
            Pattern and inspired by the concept of Dynan Prabodhini, Pune. The
            aim of the Vedant Public school is to establish such a place where
            children will achieve holistic development. Our focus of education
            is not only on academics but also to develop the student's quality
            of integrity, honesty, trust, tolerance, and compassion and also
            focuses on physical, mental, spiritual, social, and technical
            development. ”
          </p>
        </div>
      </div>
      <hr />

      <GalleryModal images={images} />

      <hr />
      <h3 className="vedant-heading">OTHER ACTIVITIES</h3>
      <GalleryModal images={vImages} /> <hr/>
      <h3 className="vedant-heading">Vedant Public School Poha.</h3>
      <GalleryModal images={vsImages} />
      <hr/>
      <div className="humanity-section" style={{ backgroundImage: `url(${humanity})` }}>
        <div className="humanity-content">
          <h2 className="humanity-heading">
            Let's Change The World <br /> With Humanity
          </h2>
          <button className="btn btn-primary" onClick={handleShowModal}>Become a Member</button>
        </div>
      </div>
      <MemberFormModal show={showModal} handleClose={handleCloseModal} />
    </div>
  );
};

export default Vedant;

import React, { useState, useEffect } from "react";
import Agricultural from "../assets/agriculture.jpeg";
import humanity from "../assets/humanity.jpg";
import placeholder1 from "../assets/2.png";
import placeholder2 from "../assets/3.png";
import placeholder3 from "../assets/4.png";
import placeholder4 from "../assets/6.png";
import placeholder5 from "../assets/5.png";
import placeholder6 from "../assets/1.png";
import a1 from "../assets/a1.png";
import a2 from "../assets/a2.png";
import a3 from "../assets/a3.png";
import a4 from "../assets/a4.png";
import a5 from "../assets/a5.png";
import b1 from "../assets/b1.png";
import b2 from "../assets/b2.png";
import b3 from "../assets/b3.png";
import b4 from "../assets/b4.png";
import b5 from "../assets/b5.png";
import c1 from "../assets/c1.png";
import c2 from "../assets/c2.png";
import c3 from "../assets/c3.png";
import c4 from "../assets/c4.png";
import c5 from "../assets/c5.png";
import d1 from "../assets/d1.png";
import d2 from "../assets/d2.png";
import d3 from "../assets/d3.png";
import d4 from "../assets/d4.png";
import e1 from "../assets/e1.png";
import e2 from "../assets/e2.png";
import e3 from "../assets/e3.png";
import e4 from "../assets/e4.png";
import f1 from "../assets/f1.png";
import f2 from "../assets/f2.png";
import f3 from "../assets/f3.png";
import f4 from "../assets/f4.png";
import f5 from "../assets/f5.png";
import f6 from "../assets/f6.png";
import f7 from "../assets/f7.png";
import f8 from "../assets/f8.png";
import f9 from "../assets/f9.png";
import CarouselComponent from "../components/CarouselComponent";
import MemberFormModal from '../components/MemberFormModal';
import "./CSS/CollegeOfAgriculure.css";

const CollegeOfAgriculture = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    console.log('Show Modal Clicked');
    setShowModal(true);
  };
  const handleCloseModal = () => {
    console.log('Close Modal Clicked');
    setShowModal(false);
  };
  const initialImages = [
    placeholder1,
    placeholder2,
    placeholder3,
    placeholder4,
    placeholder5,
    placeholder6,
  ];

  const [galleryExpanded, setGalleryExpanded] = useState(false);
  const [currentGallery, setCurrentGallery] = useState(initialImages);
  const [activeGalleryTitle, setActiveGalleryTitle] = useState("");

  const toggleGallery = (title, images) => {
    if (activeGalleryTitle === title) {
      setGalleryExpanded(!galleryExpanded);
    } else {
      setCurrentGallery(images);
      setActiveGalleryTitle(title);
      setGalleryExpanded(true);
    }
  };

  useEffect(() => {
    console.log("Gallery expanded:", galleryExpanded);
    console.log("Current gallery:", currentGallery);
  }, [galleryExpanded, currentGallery]);

  const galleries = [
    { title: "AHDS Gallery", images: [a1, a2, a3, a4, a5] },
    { title: "Sources Of Water", images: [b1, b2, b3, b4, b5] },
    { title: "Poly House", images: [c1, c2, c3, c4, c5] },
    { title: "Mother Plants", images: [d1, d2, d3, d4] },
    { title: "ALL LABS", images: [e1, e2, e3, e4] },
    { title: "Inter College Tournaments Akola", images: [f1, f2, f3, f4, f5, f6, f7, f8, f9] },
  ];

  return (
    <div className="coa-container">
      <div>
        <img src={Agricultural} alt="Agriculture Image" className="image" />
      </div>
      <h3 className="agri-heading">Agriculture College, Amkheda</h3>
      <p className="agri-para">
        " India is mainly an agriculture country and its economy largely depends
        upon agriculture. The farmer being the important element of nation,
        Geetai decided to contribute for concrete work in agriculture sector and
        started agri Education at Amkheda. Dist. Washim in the year 2013 and
        subsequently, college of agriculture in july 2015 affiliated to Dr.
        Panjabrao Deshmukh Krishi Vidyapeeth, Akola. Our Aim behind establishing
        this agri institute is not only to generate agri graduates but to
        empower the students in becoming self employed by generating agri
        Business. ”
      </p>

      <hr />

      <CarouselComponent images={initialImages} />

      <hr />

      {galleries.map((gallery, index) => (
        <div key={index} className="ahds-gallery">
          <h3 className="ahds-heading" onClick={() => toggleGallery(gallery.title, gallery.images)}>
            {gallery.title}
          </h3>
          <div className={`gallery-images ${galleryExpanded && activeGalleryTitle === gallery.title ? 'expanded' : ''}`}>
            {currentGallery.map((image, idx) => (
              <img
                key={idx}
                src={image}
                alt={`Image ${idx + 1}`}
                className="gallery-image"
              />
            ))}
          </div>
        </div>
      ))}

      <hr />

      <div className="humanity-section" style={{ backgroundImage: `url(${humanity})` }}>
        <div className="humanity-content">
          <h2 className="humanity-heading">
            Let's Change The World <br /> With Humanity
          </h2>
          <button className="btn btn-primary" onClick={handleShowModal}>Become a Member</button>
        </div>
      </div>
      <MemberFormModal show={showModal} handleClose={handleCloseModal} />
    </div>
  );
};

export default CollegeOfAgriculture;

// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Donation from './pages/Donation';
import Membership from './pages/Membership';
import Sponsorship from './pages/Sponsorship';
import CollegeOfAgriculure from './pages/CollegeOfAgriculure';
import Vedant from './pages/Vedant';
import KrushiTantra from './pages/KrushiTantra';
import LinkBharat from './pages/LinkBharat';
import Janadhar from './pages/Janadhar';
import Tourism from './pages/Tourism';
import Events from './pages/Events';
import Gallery from './pages/Gallery';
import Contacts from './pages/Contacts';
import Overview from './pages/Overview';
import Vision from './pages/Vision';
import About from './pages/About';
import Founders from './pages/Founders';
import Pillers from './pages/Pillers';
import AdminPanel from './admin/AdminPanel';
import AdminMembership from './admin/AdminMembership';
import AdminSponsorship from './admin/AdminSponsorship';
import AdminEventRegistration from './admin/AdminEventRegistration';
import AdminDonation from './admin/AdminDonation';
import ContactForms from './admin/ContactForms';
import LoginPage from './admin/LoginPage';
import PrivateRoute from './admin/PrivateRoute';
import { AuthProvider } from './admin/auth/AuthProvider';
import Committee from './pages/Committee';

const App = () => {
    const location = useLocation();
    const isAdminRoute = location.pathname.startsWith('/admin');

    return (
        <div>
            {!isAdminRoute && <Header />}
            <Routes>
                {/* Public Routes */}
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/about/overview" element={<Overview />} />
                <Route path="/about/vision-mission-values" element={<Vision />} />
                <Route path="/about/our-founders" element={<Founders />} />
                <Route path="/about/pillars-of-geetai" element={<Pillers />} />
                <Route path="/about/advisory-committee" element={<Committee />} />
                <Route path="/funding/donation" element={<Donation />} />
                <Route path="/funding/membership" element={<Membership />} />
                <Route path="/funding/sponsorship" element={<Sponsorship />} />
                <Route path="/sustainable/college-of-agriculture" element={<CollegeOfAgriculure />} />
                <Route path="/sustainable/vedant-public-school" element={<Vedant />} />
                <Route path="/sustainable/krushi-tantra-niketan" element={<KrushiTantra />} />
                <Route path="/social/link-bharat" element={<LinkBharat />} />
                <Route path="/social/janadhar" element={<Janadhar />} />
                <Route path="/social/tourism-development" element={<Tourism />} />
                <Route path="/events/registration" element={<Events />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/contacts" element={<Contacts />} />

                {/* Admin Routes */}
                <Route path="/admin/login" element={<LoginPage />} />
                <Route path="/admin" element={<PrivateRoute element={AdminPanel} />}>
                    <Route path="membership" element={<AdminMembership />} />
                    <Route path="sponsorship" element={<AdminSponsorship />} />
                    <Route path="event-registration" element={<AdminEventRegistration />} />
                    <Route path="donation" element={<AdminDonation />} />
                    <Route path="contact" element={<ContactForms />} />
                </Route>
            </Routes>
            {!isAdminRoute && <Footer />}
        </div>
    );
};

const AppWrapper = () => (
    <Router>
        <AuthProvider>
            <App />
        </AuthProvider>
    </Router>
);

export default AppWrapper;



















import React, { useRef, useState } from "react";
import "./CSS/Membership.css";
import humanity from "../assets/humanity.jpg";
import silver from "../assets/silver.png";
import MembershipForm from "../components/MembershipForm";
import MemberFormModal from '../components/MemberFormModal';

const Membership = () => {

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    console.log('Show Modal Clicked');
    setShowModal(true);
  };
  const handleCloseModal = () => {
    console.log('Close Modal Clicked');
    setShowModal(false);
  };

  return (
    <div className="membership-container">
      <marquee>
        <h4>Helping People By Charity Is The Most Human Thing We Can Do.</h4>
      </marquee>
      <div
        className="humanity-section"
        style={{ backgroundImage: `url(${humanity})` }}
      >
        <div className="humanity-content">
          <h2 className="humanity-heading">
            Let's Change The World <br /> With Humanity
          </h2>
          <button className="btn btn-primary" onClick={handleShowModal}>
            Become a Member
          </button>
        </div>
      </div>

      <hr />

      <h2 className="membership-heading">New Membership Required For Geetai</h2>

      <div className="membership-content">
        <img src={silver} className="silver" alt="Silver" />
        <div className="membership-text">
          <p className="membership-para">
            विनोबा भावे यांची अजरामर गीताई आणि विनोबा भावे यांच्या माणुसकीचा आदर्श
            डोळ्यांसमोर ठेवून समाजसेवेचा, समाजप्रबोधनाचा आणि नव युगाचा विचार मनात
            ठेवून प्रबळ इच्छाशक्तीच्या आणि आत्मविश्वासाच्या बळावर 'गीताई ह्युमनकाईंड
            डेव्हलपमेंट ट्रस्ट, पुणे ' ची स्थापना इ.स. २००१ मध्ये झाली. विचारांना
            कृतीची जोड असेल तर कोणतीही गोष्ट अशक्य नाही या विचारसरणीला अनुसरुन
            संस्थेने विविध उपक्रम राबविण्यास सुरुवात केली. गीताई ह्युमनकाईंड
            डेव्हलपमेंट ट्रस्ट प्रामुख्याने शिक्षण, आरोग्य, सामाजिक, कृषी आणि
            आध्यात्मिक अशा पाच क्षेत्रांत शहरी आणि ग्रामीण भागात गेल्या १५
            वर्षांपासून सतत कार्यरत आहे. नवनवीन उपक्रम राबविण्यामध्ये संस्थेचा
            हातखंडा आहे. यामध्ये, लहान मुलांसाठी बाल संस्कार व साहसी शिबिर, शेतकरी
            मार्गदर्शन, कृषी पर्यटन, महिला सक्षमीकरण, संपूर्ण ग्राम विकास (Digital
            Gao), सरस्वती ॲग्रोटेक अशा विविध नाविन्यपूर्ण उपक्रमांचा समावेश आहे.
            सध्याच्या आधुनिक तंत्रज्ञानाच्या युगात लोप पावत चाललेली मूल्ये, संस्कार
            पुनरुर्जिवीत करण्यासाठी, विद्यार्थ्यांच्या विचार क्षमतेला चालना
            देण्यासाठी, मीपणा आणि एकटेपणा झटकून टाकून समाजाप्रती असलेली सामाजिक
            बांधिलकी वृद्धिंगत करण्यासाठी गीताई ह्युमनकाईंड डेव्हलपमेंट ट्र्स्ट
            कार्यरत आहे. या ट्र्स्ट अंतर्गत सुरु केलेल्या विविध उपक्रमांबाबत आपणांस
            योग्य ती जाण आहेच. या ट्र्स्ट्चा शैक्षणिक, स्वास्थ्य, सामाजिक, कृषी आणि
            आध्यात्मिक अशा पाच क्षेत्रांतील कामगिरीचा चढता आलेख आपण पाहिला आहे. याच
            विचारांना अनुसरुन या कार्यामध्ये एक मोलाची भर टाकण्याच्या उद्देशाने
            यावर्षीपासून आपण दरमहा एक मासिक प्रसिद्ध करण्याचा मानस आपणांसमोर मांडत
            आहोत. या मासिकामध्ये शिक्षण, आरोग्य, सामाजिक, कृषी आणि आध्यात्मिक अशा
            पाच क्षेत्रांवर आधारित लेख, कविता, महत्वाच्या टीप्स, विनोद यांचा समावेश
            असेल. हे मासिक संस्थेशी निगडीत सर्व विद्यार्थी, पालक, कंपनीमध्ये काम
            करणारे कर्मचारी यांच्या पर्यंत पोहचण्यासाठी ट्र्स्ट प्रयत्नशील राहील.
            तसेच या मासिकाचे सभासदत्व घेताना सभासदाला या मासिकासोबत मिळणा-या विविध
            सवलतींचा लाभही घेता येणार आहे. त्याची माहिती पुढीलप्रमाणॆ : <br />
            तरी वर नमुद केलेल्या सर्व बाबींसदर्भात आपल्या सुचना आणि विविध कल्पनांची
            आम्ही वाट पहात आहोत. आपल्या सूचना आणि कल्पना आमच्यासाठी अधिक मोलाच्या
            आहेत.आपण गीताई ह्युमनकाईंड डेव्हलपमेंट ट्र्स्टच्या विविध उपक्रमांमध्ये
            सक्रियपणे सहभागी होऊन आमच्या या मोलाच्या कार्यास हातभार लावावा. आणि
            आम्हांस योग्य ते मार्गदर्शन करावे. आपला स्नेह असाच वृद्धिंगत होवो अशी
            प्रामाणिक इच्छा !!! <br /> आपला विश्वासू, <br />
            गीताई ह्युमनकाईंड डेव्हलपमेंट ट्र्स्ट ,पुणे
          <p className="membership-donation">"सभासद वर्गणी १००१ / -"</p>
          </p>
          <p className="membership-para">
            तरी वर नमुद केलेल्या सर्व बाबींसदर्भात आपल्या सुचना आणि विविध कल्पनांची
            आम्ही वाट पहात आहोत. आपल्या सूचना आणि कल्पना आमच्यासाठी अधिक मोलाच्या
            आहेत.आपण गीताई ह्युमनकाईंड डेव्हलपमेंट ट्र्स्टच्या विविध उपक्रमांमध्ये
            सक्रियपणे सहभागी होऊन आमच्या या मोलाच्या कार्यास हातभार लावावा. आणि
            आम्हांस योग्य ते मार्गदर्शन करावे. आपला स्नेह असाच वृद्धिंगत होवो अशी
            प्रामाणिक इच्छा !!! <br /> आपला विश्वासू, <br />
            गीताई ह्युमनकाईंड डेव्हलपमेंट ट्र्स्ट ,पुणे
          </p>
        </div>
          <div>
          </div>
      </div>


        <MemberFormModal show={showModal} handleClose={handleCloseModal} />
    </div>
  );
};

export default Membership;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import "./CSS/AdminEventRegistration.css";

const AdminEventRegistration = () => {
  const [registrations, setRegistrations] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); 
  const [paymentModeFilter, setPaymentModeFilter] = useState('all');
  const [dateRange, setDateRange] = useState({ start: '', end: '' });


  useEffect(() => {
    axios
      .get("http://localhost:5000/admin/event-registrations")
      .then((response) => setRegistrations(response.data))
      .catch((error) =>
        console.error("Error fetching event registration data:", error)
      );
  }, []);

  const handleDelete = (id) => {
    axios
      .delete(`http://localhost:5000/admin/event-registrations/${id}`)
      .then(() =>
        setRegistrations(
          registrations.filter((registration) => registration.id !== id)
        )
      )
      .catch((error) => console.error("Error deleting registration:", error));
  };

  const handleEdit = (id) => {
    setEditingId(id);
  };

  const handleCloseModal = () => {
    setEditingId(null);
  };

  const handleUpdate = (updatedRegistration) => {
    setRegistrations(
      registrations.map((registration) =>
        registration.id === updatedRegistration.id
          ? updatedRegistration
          : registration
      )
    );
  };


  const filteredRegistrations = registrations
        .filter(registration => 
            (searchTerm === '' || 
              registration.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
              registration.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
              registration.mobileNumber.toLowerCase().includes(searchTerm.toLowerCase()))
            &&
            (paymentModeFilter === 'all' || registration.paymentMode === paymentModeFilter)
            &&
            (dateRange.start === '' || dateRange.end === '' || 
            (new Date(registration.createdAt) >= new Date(dateRange.start) && new Date(registration.createdAt) <= new Date(dateRange.end)))
        );


  return (
    <div className="admin-event-registration">
      <div className="header-section">
        <h2 className="admin-membership-heading">Event Registrations</h2>
        <input
          type="text"
          placeholder="Search..."
          className="search-bar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
                <div className="filter-section">
                        <Form.Group controlId="paymentModeFilter">
                            <Form.Label>Payment Mode</Form.Label>
                            <Form.Control 
                                as="select" 
                                value={paymentModeFilter} 
                                onChange={(e) => setPaymentModeFilter(e.target.value)}
                            >
                                <option value="all">All</option>
                                <option value="online">Online</option>
                                <option value="cash">Cash</option>
                                <option value="cheque">Cheque</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="dateRange">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control 
                            
                                type="date" 
                                placeholder="Start Date"
                                value={dateRange.start}
                                onChange={(e) => setDateRange({ ...dateRange, start: e.target.value })}
                            />
                          </Form.Group>
                          <Form.Group controlId="dateRange">
                             <Form.Label>End Date</Form.Label>
                            <Form.Control 
                                type="date" 
                                placeholder="End Date"
                                value={dateRange.end}
                                onChange={(e) => setDateRange({ ...dateRange, end: e.target.value })}
                            />
                        </Form.Group>
              
                    </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Occupation</th>
            <th>Current Address</th>
            <th>Permanent Address</th>
            <th>Pincode</th>
            <th>Geetai Member</th>
            <th>Status</th>
            <th>Remark</th>
            <th>Payment Mode</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredRegistrations.map((registration) => (
            <tr key={registration.id}>
              <td>{registration.id}</td>
              <td>{registration.fullName}</td>
              <td>{registration.email}</td>
              <td>{registration.mobileNumber}</td>
              <td>{registration.occupation}</td>
              <td>{registration.currentAddress}</td>
              <td>{registration.permanentAddress}</td>
              <td>{registration.pincode}</td>
              <td>{registration.geetaiMember}</td>
              <td>{registration.status}</td>
              <td>{registration.remark}</td>
              <td>{registration.paymentMode}</td>
              <td>{new Date(registration.createdAt).toLocaleString()}</td>
              <td>{new Date(registration.updatedAt).toLocaleString()}</td>
              <td className="button-td">
                <button
                  onClick={() => handleEdit(registration.id)}
                  className="edit-btn"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(registration.id)}
                  className="delete-btn"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {editingId && (
        <EditEventRegistrationModal
          id={editingId}
          show={Boolean(editingId)}
          onClose={handleCloseModal}
          onUpdate={handleUpdate}
        />
      )}
    </div>
  );
};

const EditEventRegistrationModal = ({ id, show, onClose, onUpdate }) => {
  const [registration, setRegistration] = useState(null);

  useEffect(() => {
    axios
      .get(`http://localhost:5000/admin/event-registrations/${id}`)
      .then((response) => setRegistration(response.data))
      .catch((error) =>
        console.error("Error fetching registration data:", error)
      );
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .put(
        `http://localhost:5000/admin/event-registrations/${id}`,
        registration
      )
      .then(() => {
        onUpdate({ ...registration, id });
        onClose();
      })
      .catch((error) =>
        console.error("Error updating registration data:", error)
      );
  };

  return (
    <Modal show={show} onHide={onClose} className="edit-event-modal">
      <Modal.Header closeButton>
        <Modal.Title>Edit Event Registration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {registration ? (
          <Form onSubmit={handleSubmit} className="edit-form">
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={registration.fullName}
                onChange={(e) =>
                  setRegistration({ ...registration, fullName: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={registration.email}
                onChange={(e) =>
                  setRegistration({ ...registration, email: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formMobile">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="text"
                value={registration.mobileNumber}
                onChange={(e) =>
                  setRegistration({
                    ...registration,
                    mobileNumber: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="formOccupation">
              <Form.Label>Occupation</Form.Label>
              <Form.Control
                type="text"
                value={registration.occupation}
                onChange={(e) =>
                  setRegistration({
                    ...registration,
                    occupation: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="formCurrentAddress">
              <Form.Label>Current Address</Form.Label>
              <Form.Control
                type="text"
                value={registration.currentAddress}
                onChange={(e) =>
                  setRegistration({
                    ...registration,
                    currentAddress: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="formPermanentAddress">
              <Form.Label>Permanent Address</Form.Label>
              <Form.Control
                type="text"
                value={registration.permanentAddress}
                onChange={(e) =>
                  setRegistration({
                    ...registration,
                    permanentAddress: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="formPincode">
              <Form.Label>Pincode</Form.Label>
              <Form.Control
                type="text"
                value={registration.pincode}
                onChange={(e) =>
                  setRegistration({ ...registration, pincode: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formGeetaiMember">
              <Form.Label>Geetai Member</Form.Label>
              <Form.Control
                type="text"
                value={registration.geetaiMember}
                onChange={(e) =>
                  setRegistration({
                    ...registration,
                    geetaiMember: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="formStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={registration.status}
                onChange={(e) =>
                  setRegistration({ ...registration, status: e.target.value })
                }
              >
                <option value="success">Success</option>
                <option value="pending">Pending</option>
                <option value="rejected">Rejected</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formRemark">
              <Form.Label>Remark</Form.Label>
              <Form.Control
                as="textarea"
                value={registration.remark}
                onChange={(e) =>
                  setRegistration({ ...registration, remark: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formPaymentMode">
              <Form.Label>Payment Mode</Form.Label>
              <Form.Control
                as="select"
                value={registration.paymentMode}
                onChange={(e) =>
                  setRegistration({
                    ...registration,
                    paymentMode: e.target.value,
                  })
                }
              >
                <option value="online">Online</option>
                <option value="cash">Cash</option>
                <option value="cheque">Cheque</option>
              </Form.Control>
            </Form.Group>
            <div className="modal-footer">
              <Button variant="primary" type="submit">
                Save
              </Button>
              <Button variant="secondary" onClick={onClose}>
                Close
              </Button>
            </div>
          </Form>
        ) : (
          <p>Loading...</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AdminEventRegistration;

import React, { useState } from "react";


const GalleryModal = ({ images }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);


  const openModal = (index) => {
      setCurrentImage(index);
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
  
    const previousImage = () => {
      setCurrentImage((prev) => (prev === 0 ? images.length - 1 : prev - 1));
    };
  
    const nextImage = () => {
      setCurrentImage((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    };

  return (
   <div>
    <div className="gallery">
        {images.map((image, index) => (
          <div
            key={index}
            className="gallery-item"
            onClick={() => openModal(index)}
          >
            <img src={image} alt='image' />
          </div>
        ))}
      </div>

      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <span className="close">&times;</span>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="arrow left-arrow" onClick={previousImage}>
              &#10094;
            </button>
            <img
              src={images[currentImage]}
              alt='image'
              className="modal-image"
            />
            <button className="arrow right-arrow" onClick={nextImage}>
              &#10095;
            </button>
          </div>
        </div>
      )}
   </div>
  );
};

export default GalleryModal;
